import React from 'react'
import { Col, Row } from 'antd'
import { Source, UnitsEnum, Indicator } from '../config/indicators-model'

export type ChartMetaSource = { global: Source[]; specific: { sources: Source[]; name: string; derivation: boolean }[] }

type Props = {
  units: UnitsEnum[]
  sources: ChartMetaSource
  description: string
  timeRange: number[]
  periodicity: string
  chart: Indicator
}

export const ChartMeta: React.FC<Props> = ({ sources, description, timeRange, units, periodicity, chart }) => {
  return (
    <div className="db-search-card">
      <h1>Más información sobre {chart.longTitle}</h1>
      
      <Row>
        <Col span={12}>
          <h2>Serie Temporal</h2>
          {timeRange[0]}-{timeRange[1]}
        </Col>
        <Col span={12}>
          <h2>Periodicidad</h2>
          {periodicity}
        </Col>
      </Row>
      
      <Row>
        <Col span={12}>
          <h2>Unidades</h2>
          {units.join(', ')}
        </Col>
        <Col span={12}>
          {sources.global.length ? (
            <>
              <h2>Fuentes principales</h2>
              <ul>
              {sources.global.map((s, index) => (
                  <li key={index}>
                    <a href={s.link} target="_blank" rel="noopener noreferrer">
                      {s.name}
                    </a>
                  </li>
              ))}
              </ul>
            </>
          ) : (
            ''
          )}
          {sources.specific.length ? (
            <>
              <h3>Fuentes detalladas</h3>
              {sources.specific.map(s => (
                <Row className="chart-source">
                  <Col span={14}>{s.name}</Col>
                  <Col span={10}>
                    {s.derivation ? 'Cálculo propio a partir de: ' : ''}
                    {s.sources.map((ss, index) => (
                      <>
                        {index > 0 ? ', ' : ''}
                        <a href={ss.link} target="_blank" rel="noopener noreferrer">
                          {ss.name}
                        </a>
                      </>
                    ))}
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            ''
          )}
        </Col>
      </Row>
      
      
      <h2>Descripción</h2>
      <Row style={{ marginTop: 12 }}>
        <Col span={24}>{description}</Col>
      </Row>
    </div>
  )
}
