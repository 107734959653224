import React from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import reactStringReplace from 'react-string-replace'
import { Chart } from '../../components/Chart'

type Props = {
  postId: number
  postTitle: string
  postDescription: string
  postUrl: string
  postBody: string
  postDate: Date
  postPreview: boolean
}

export const BlogPost: React.FC<Props> = ({ postTitle, postUrl, postDescription, postBody, postDate, postPreview }) => {
  const formatDate = (d: Date) => {
    const date = new Date(d)

    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL)
  }

  const encodedURL = encodeURIComponent(postUrl)

  const regexComp = new RegExp('{chart id="([^"]+)"}', 'igs')
  const regexRes = [...postBody.matchAll(regexComp)]

  const rawRegex = new RegExp('<iframe src="(.+)".*?iframe>', 'igs')
  const fixedPostBody = postBody.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  const rawRegexResults = [...fixedPostBody.matchAll(rawRegex)]

  const rawBody = fixedPostBody.replace(/{chart id="([^"]+)"}/g, '').replace(/<(.|\n)*?>/g, '').replace(/.+iframe src="(.+)".*?iframe[^\s]+/g, '')

  const bodyWithCharts =
    regexRes.length > 0
      ? reactStringReplace(fixedPostBody, regexComp, (match, i) => (
          <Chart key={i} chart={match.substring(0, 2)} subChart={match} hideMeta={true}/>
        ))
      : [fixedPostBody]

  const bodyWithIframes =
    rawRegexResults.length > 0
      ? reactStringReplace(bodyWithCharts, rawRegex, (match, i) => (
          <iframe
            key={i}
            src={match}
            style={{
              width: '100%',
              height: 600,
              border: '0px none',
            }}
          />
        ))
      : bodyWithCharts

  return (
    <Post>
      <header>
        <h2>
          <Link to={`/noticias/${encodedURL}`}>{postTitle}</Link>
        </h2>
        <small>Publicado el {formatDate(postDate)}</small>
      </header>
      <article>
        {postPreview ?
          rawBody.slice(0, 500)
        : 
          bodyWithIframes.map(n =>
            typeof n === 'object' ? n : <div key={n as string} dangerouslySetInnerHTML={{ __html: n as string }} />,
          )
        } <Link to={`/noticias/${encodedURL}`}>...seguir leyendo</Link>
      </article>
    </Post>
  )
}

const Post = styled.div`
  width: 100%;

  h2 {
    border-bottom: 1px solid #efefef;
    margin-bottom: 5px;
    margin-top: 40px;
  }

  header small {
    font-weight: bold;
  }

  header {
    margin-bottom: 40px;
  }

  img {
    float: right;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #eee;
    margin: 10px;
  }
`
