import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import { MainMenu } from '../layouts/components/MainMenu'
import { Helmet } from "react-helmet";

export const ClimateChange: React.FC = () => {
  return (
    <BaseLayout>
      <Helmet>
        <title>El cambio climático: ¿Qué es?.</title>
        <meta name="description" content="Algunas nociones sobre el cambio climático" />
        <meta name="keywords" content="Cambio climático, transición ecológica, artículos, noticias, estudios, publicaciones, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MainMenu />
      <h2>¿Qué es el cambio climático?</h2>
      <p>
        El cambio climático es un hecho irrefutable, altamente respaldado por datos científicos. No solo esto, el ser
        humano es el principal causante de esta problemática, a pesar del esfuerzo que durante años algunos
        negacionistas han ejercido para hacernos creer que el calentamiento global que estamos padeciendo es un proceso
        meramente natural.
      </p>
      <p>
        Además, los impactos del cambio climático tienen una gran incidencia a nivel global y esta se intensificará en
        los próximos años. Asimismo, podemos afirmar que estos impactos afectarán de manera desigual a la población
        mundial. Los países menos desarrollados y las comunidades más pobres serán los que paguen las consecuencias, a
        pesar de ser los países desarrollados los que más han contribuido al calentamiento global históricamente.
      </p>
      <p>
        Ante la amenaza que supone el cambio climático, países de todo el mundo ya han o están desarrollando diversas
        iniciativas para hacerle frente, aunque bien es cierto que la intensidad de estos esfuerzos es muy distinta en
        función de las condiciones y los recursos del país. No obstante, es indispensable una acción global, inmediata y
        de mayor intensidad para no superar el umbral crítico de 1,5°C que marca el IPCC.
      </p>
      <h3>Evidencias</h3>
      <p>Existen numerosas definiciones de cambio climático:</p>
      <p>
        <ul>
          <li>
            La CMNUCC lo define como un cambio de clima atribuido directa o indirectamente a la actividad humana que
            altera la composición de la atmósfera mundial y que se suma a la variabilidad natural del clima observada
            durante períodos de tiempo comparables.
          </li>
          <li>
            El IPCC lo define como cualquier cambio en el clima con el tiempo debido a la variabilidad natural o como
            resultado de actividades humanas.
          </li>
          <li>En meteorología se llama cambio climático a la alteración de las condiciones predominantes.</li>
        </ul>
      </p>
      <p>
        Sea como fuere, en la actualidad existe una amplia gama de evidencias científicas de que el cambio climático es
        un hecho irrefutable y no cabe duda de que el aumento de los niveles de gases de efecto invernadero en la
        atmósfera ha provocado que la Tierra se caliente.
      </p>
      <p>
        Estas evidencias revelan que hoy en día el calentamiento del planeta se está dando aproximadamente diez veces
        más rápido que la tasa promedio de calentamiento tras las épocas glaciales. Además, en las últimas décadas, el
        dióxido de carbono de origen antropogénico está aumentando a una velocidad 250 veces mayor que el proveniente de
        fuentes naturales[1].
      </p>
      <p>
        A continuación, se analizarán 7 de los indicadores más reconocidos por la comunidad científica para explicar los
        cambios que está sufriendo el clima. Estos indicadores fueron definidos por el Sistema Mundial de Observación
        del Clima (GCOS por sus siglas en inglés) y fueron adoptados por la Organización Meteorológica Mundial (WMO) y
        el IPCC, de modo que cuentan con un respaldo indiscutible a nivel internacional.
      </p>
      <img src="/text-images/otea-figure-2.png" />
      <h4>Temperatura y Energía</h4>
      <p>
        La temperatura en superficie y el calor oceánico son dos de los indicadores más estudiados por los climatólogos
        para explicar el cambio que ha sufrido el clima desde la era preindustrial (1850-1900). La temperatura media
        mundial, ya ha aumentado aproximadamente 1,14°C desde la era preindustrial, principalmente a causa del aumento
        en las emisiones de GEI[2]. Si bien esto es alarmante, lo realmente preocupante es la velocidad a la que la
        temperatura ha subido en las últimas décadas. Y es que la mayor parte de este calentamiento se ha dado en los
        últimos 40 años, siendo 2016 el año más cálido para el que se tienen datos y el periodo 2015-2019 el más cálido
        del registro de temperaturas mundiales[3]. Además, en 2018, el aumento de la temperatura fue excepcionalmente
        alto en algunas zonas, como en el Ártico, donde las anomalías de la temperatura media anual superaron los 2°C de
        forma generalizada y los 3°C en determinados lugares[4].
      </p>
      <img src="/text-images/otea-figure-3.png" />
      <sub>
        Anomalías de la temperatura media mundial con respecto al periodo 1850-1900 (°C). Fuente: Centro Hadley de la
        Oficina Meteorológica del Reino Unido.
      </sub>
      <p>
        A pesar de que la temperatura en la superficie oceánica también se ha medido durante décadas y presenta un
        calentamiento de más de 0,33°C desde 1969[5], esta tiende a estar influida por los fenómenos de El Niño y La
        Niña, de modo que el contenido calórico del océano resulta más representativo, ya que aumenta de forma más
        paulatina y las fluctuaciones interanuales son menos pronunciadas. Así, el contenido calórico del océano
        proporciona una medida directa de la energía (procedente de los GEI) que se acumula en las capas superiores del
        océano. En este sentido, se estima que el 90% de la energía sobrante del planeta es absorbida y almacenada por
        los océanos[6], de modo que el calor oceánico ha ido en aumento, hasta ubicarse en 2019 en nuevos máximos en los
        intervalos de 0-700m y 0-2000m de profundidad, superando así el record de 2018[3].
      </p>
      <img src="/text-images/otea-figure-4.png" />
      <h4>Composición atmosférica</h4>
      <p>
        La concentración de gases de efecto invernadero (GEI) en la atmósfera es un factor decisivo en lo que respecta
        al cambio climático, siendo el resultado del equilibrio entre las fuentes y los sumideros de este tipo de gases.
        En este sentido, el CO2 atmosférico es el indicador más relevante, pero es importante tener en cuenta también
        gases como el óxido nitroso (N2O) o el metano (CH4), uno de los gases más contaminantes.
      </p>
      <p>
        Las emisiones de GEI derivadas de fuentes fósiles han aumentado de forma continuada desde la era preindustrial y
        de forma exponencial los últimos años. De hecho, esta tendencia solo se ha visto interrumpida durante las crisis
        económicas mundiales. En consecuencia, las concentraciones atmosféricas de este tipo de gases alcanzaron nuevos
        valores máximos en 2018, situándose los promedios mundiales en: 407,8ppm de CO2, 1 869ppmm de CH4 y 331,1ppmm de
        N2O. Esos valores corresponden, al 147%, el 259% y el 123% de los niveles preindustriales respectivamente[4].
      </p>
      <img src="/text-images/otea-figure-5.png" />
      <sub>
        Concentración atmosférica de CO2, CH4 y N2O. Fila superior: Medias mundiales􀀁de la fracción molar (unidad que
        mide la concentración) del CO2 expresadas en partes por millón (izquierda), del CH4, expresadas en partes por
        mil millones (centro), y del N2O, expresadas en partes por mil millones (derecha) de 1984 a 2017. La línea roja
        corresponde a la fracción molar media mensual que resulta de suprimir la variación estacional; los puntos y la
        línea azules representan las medias mensuales. Fila inferior: Los índices de aumento representan el incremento
        de las medias anuales sucesivas de las fracciones molares del CO2, en partes por millón por año (izquierda), del
        CH4, en partes por mil millones por año (centro), y del N2O, en partes por mil millones por año (derecha).
        Fuente: Vigilancia de la Atmósfera Global de la WMO.
      </sub>
      <h4>Océanos y Agua</h4>
      <p>
        En el análisis de los océanos, se detectaron 2 indicadores clave que condicionan el cambio climático: el nivel
        del mar y la acidificación del océano. En lo relativo al nivel del mar, el incremento acelerado que ha sufrido,
        se debe al aumento de temperatura y al deshielo[7]: de 1901 a 2010, el nivel medio mundial del mar ascendió
        19cm[8] y en 2019, el nivel medio del mar a escala mundial alcanzó su valor más alto desde que se dispone de
        registros de altimetría de alta precisión (enero de 1993)[3]. Sin embargo, lo realmente preocupante es que la
        tasa de aumento de las dos últimas décadas casi dobla la del último siglo y cada año se está acelerando
        ligeramente[9].
      </p>
      <p>
        Por otro lado, el océano ejerce la función de sumidero de CO2 y esto hace que el pH se modifique, dando pie a la
        acidificación de los océanos. Así, los océanos han absorbido entre el 20 y el 30% de las emisiones
        antropogénicas (entre 7.200 y 10.800 millones de toneladas métricas por año)[10][11]. Este fenómeno merma la
        capacidad de ciertos organismos marinos (como corales o moluscos) para crear y mantener su caparazón y
        esqueleto. En este sentido, los datos recopilados durante las últimas 3 décadas en mar abierto indican una clara
        tendencia a la reducción del pH, habiendo aumentado la acidificación de los océanos en torno a un 30%[12].
      </p>
      <img src="/text-images/otea-figure-6.png" />
      <sub>Cambios en el pH oceánico. Fuente: GCOS</sub>
      <h4>Criosfera</h4>
      <p>
        La criosfera comprende la precipitación sólida, el manto de nieve, el hielo marino, el hielo lacustre y fluvial,
        los glaciares, los casquetes de hielo, los mantos de hielo, el permafrost y el suelo congelado estacional. Si
        bien es cierto que las observaciones realizadas mediante satélites revelan que, en primavera, la cubierta de
        nieve del hemisferio norte ha disminuido durante las últimas cinco décadas y que la nieve se derrite antes[13],
        de entre todos estos elementos los principales indicadores del clima son los glaciares y la extensión del hielo
        marino en el Ártico y la Antártida.
      </p>
      <p>
        El Servicio Mundial de Vigilancia de los Glaciares tiene como referencia diversos glaciares a nivel mundial que
        abarcan más de 19 regiones montañosas. Los resultados obtenidos del análisis de los mismos indican que el año
        hidrológico 2018/2019 fue el 32º año consecutivo de balance de masa negativo. Además, la pérdida acumulada de
        hielo desde 1970 asciende 23 metros de equivalente en agua[3].
      </p>
      <img src="/text-images/otea-figure-7.png" />
      <sub>
        Cambio global acumulativo de masa de los glaciares observados. Fuente: Servicio Mundial de Vigilancia de los
        Glaciares
      </sub>
      <p>
        En cuanto a la extensión del hielo marino en el ártico hemos de destacar que, según el Centro Nacional de Datos
        de Hielo y Nieve de la NASA, en febrero de 2018 se registró un promedio de 13,95 millones de km2, lo que
        representa una disminución de la tasa del 13,2% por década. Y es que, en marzo de los últimos años se han
        registrado las extensiones máximas de hielo más bajas observadas desde 1979. Además, la extensión mínima diaria
        del hielo marino del Ártico en septiembre de 2019 fue la segunda más baja del registro satelital[3].
      </p>
      <img src="/text-images/otea-figure-8.png" />
      <p>
        Por otro lado, en la Antártida, que contiene un volumen de hielo equivalente a una subida de 57,2m del nivel del
        mar, la pérdida de masa se incrementó 40Gt/año en el periodo de 1979-1989, 50Gt/año de 1989–1999, 166Gt/año de
        1999–2009 y 252Gt/año de 2009–2017[4], llegando a registrar una pérdida de aproximadamente 148000 millones de
        toneladas de hielo durante el periodo 1993-2019[14]. Además, en 2019 los meses de mayo, junio y julio
        registraron una expansión mensual reducida sin precedentes[3].
      </p>
      <img src="/text-images/otea-figure-9.png" />
      <sub>Diferencia de la extensión del hielo marino del Antártida de 1981-2010. Fuente: UK Met Office</sub>
      <p>
        <small>
          [1] Vostok ice core data; NOAA Mauna Loa CO2 record Gaffney, O.; Steffen, W. (2017) "The Anthropocene
          equation," The Anthropocene Review (Volume 4, Issue 1, April 2017), 53-61.
        </small>
      </p>
      <p>
        <small>
          [2]https://www.ncdc.noaa.gov/monitoring-references/faq/indicators.php
          https://crudata.uea.ac.uk/cru/data/temperature/ http://data.giss.nasa.gov/gistemp
        </small>
      </p>
      <p>
        <small>
          [3] WMO. (2020). WMO Statement on the State of the Global Climate in 2019. World Meteorological Organization.
          Geneva: Publications Board WMO. https://library.wmo.int/doc_num.php?explnum_id=10211
        </small>
      </p>
      <p>
        <small>
          [4] WMO. (2019). WMO Statement on the State of the Global Climate in 2018. World Meteorological Organization.
          Geneva: Publications Board WMO. https://library.wmo.int/doc_num.php?explnum_id=5789
        </small>
      </p>
      <p>
        <small>
          [5] Levitus, S.; Antonov, J.; Boyer, T.; Baranova, O.; Garcia, H.; Locarnini, R.; Mishonov, A.; Reagan, J.;
          Seidov, D.; Yarosh, E.; Zweng, M. (2017). NCEI ocean heat content, temperature anomalies, salinity anomalies,
          thermosteric sea level anomalies, halosteric sea level anomalies, and total steric sea level anomalies from
          1955 to present calculated from in situ oceanographic subsurface profile data (NCEI Accession 0164586).
          Version 4.4. NOAA National Centers for Environmental Information. Dataset. doi: 10.7289/V53F4MVP
          https://www.nodc.noaa.gov/OC5/3M_HEAT_CONTENT/index3.html
        </small>
      </p>
      <p>
        <small>
          [6] Von Schuckmann, K., Cheng, L., Palmer, D., Hansen, J., Tassone, C., Aich, V., Adusumilli, S., Beltrami,
          H., Boyer, T., Cuesta-Valero, F., Desbruyeres, D., Domingues, C., Garcia-Garcia, A., Gentine, P., Gilson, J.,
          Gorfer, M., Haimberger, L., Ishii, M., Johnson, G., Killick, R., King, B., Kirchengast. G., Kolodziejczyk, N.,
          Lyman, J., Marzeion, B., Mayer, M., Monier, M., Monselesan, D., Purkey, S., Roemmich, D., Schweiger, A.,
          Seneviratne, S., Shepherd, A., Slater, D., Steiner, A., Straneo, F., Timmermans, ML., Wijffels, S. (2020).
          Heat stored in the Earth system: where does the energy go? Earth System Science Data (Volume 12, Issue 3, 07
          September 2020), 2013-2041.
        </small>
      </p>
      <p>
        <small>
          [7] WCRP Global Sea Level Budget Group: Global sea-level budget 1993–present, Earth Syst. Sci. Data, 10,
          1551–1590, https://doi.org/10.5194/essd-10-1551-2018, 2018.
        </small>
      </p>
      <p>
        <small>
          [8] IPCC. (2014). Fifth Assesment Report. Switzerland: Intergovernmental Panel on Climate Change.
          https://www.ipcc.ch/assessment-report/ar5/
        </small>
      </p>
      <p>
        <small>
          [9] R. S. Nerem, B. D. Beckley, J. T. Fasullo, B. D. Hamlington, D. Masters and G. T. Mitchum.
          Climate-change–driven accelerated sea-level rise detected in the altimeter era. PNAS, 2018 DOI:
          10.1073/pnas.1717312115
        </small>
      </p>
      <p>
        <small>
          [10] C. L. Sabine et.al., “The Oceanic Sink for Anthropogenic CO2,” Science vol. 305 (16 July 2004), 367-371
        </small>
      </p>
      <p>
        <small>
          [11] Special Report on the Ocean and Cryosphere in a Changing Climate, Technical Summary, Chapter TS.5,
          Changing Ocean, Marine Ecosystems, and Dependent Communities, Section 5.2.2.3.
        </small>
      </p>
      <p>
        <small>
          [12] http://www.pmel.noaa.gov/co2/story/What+is+Ocean+Acidification%3F
          http://www.pmel.noaa.gov/co2/story/Ocean+Acidification
        </small>
      </p>
      <p>
        <small>
          [13] National Snow and Ice Data Center Robinson, D. A., D. K. Hall, and T. L. Mote. 2014. MEaSUREs Northern
          Hemisphere Terrestrial Snow Cover Extent Daily 25km EASE-Grid 2.0, Version 1. [Indicate subset used]. Boulder,
          Colorado USA. NASA National Snow and Ice Data Center Distributed Active Archive Center. doi:
          https://doi.org/10.5067/MEASURES/CRYOSPHERE/nsidc-0530.001. http://nsidc.org/cryosphere/sotc/snow_extent.html
          Rutgers University Global Snow Lab, Data History.
        </small>
      </p>
      <p>
        <small>
          [14] Velicogna, I., Mohajerani, Y., A, G., Landerer, F., Mouginot, J., Noel, B., Rignot, E., Sutterly, T., van
          den Broeke, M., van Wessem, M., Wiese, D. (2020). Continuity of ice sheet mass loss in Greenland and
          Antarctica from the GRACE and GRACE Follow‐On missions. Geophysical Research Letters (Volume 47, Issue 8, 28
          April 2020, e2020GL087291.
        </small>
      </p>
      <h3>Causas</h3>
      <p>
        Los cambios radicales en el clima han existido desde el nacimiento del planeta, debido a modificaciones
        naturales en la órbita, la rotación o la inclinación de la Tierra. También a consecuencia de eventos naturales
        extraordinarios como pueden ser las erupciones volcánicas. Claro ejemplo de ello son las glaciaciones, que
        producen cambios en el clima por la modificación en la forma de la órbita de la Tierra y de la inclinación del
        planeta con respecto a su eje. El periodo interglaciar en el que nos encontramos produce una estabilidad
        climática que permite el desarrollo y el crecimiento de la civilización humana.
      </p>
      <h4>El efecto invernadero</h4>
      <p>
        El efecto invernadero juega un papel fundamental en el clima, siendo el proceso natural que permite mantener las
        condiciones necesarias para que la vida sea posible en la Tierra. La atmósfera captura algunos de los rayos del
        sol que llegan a la corteza terrestre, manteniéndolos dentro para conseguir una temperatura media de 15°C, que
        de lo contrario sería de -18°C. Así, la atmósfera está compuesta por diversos gases naturales (N2, O2 y Ar) que
        constituyen un 99,93% del total. Son otros gases, como el H2O, CO2, CH4, N2O y el O3, los que tienen una mayor
        incidencia en el efecto invernadero a pesar de que conforman solo el 0,07%. En la proporción adecuada, estos
        gases cumplen su cometido, pero la influencia humana en este proceso natural ha hecho del calentamiento global
        la principal causa del cambio climático desde la mitad del siglo XX[1].
      </p>
      <h4>Gases de efecto invernadero</h4>
      <p>
        Existen dos tipos de gases de efecto invernadero (estos bloquean el calor y no permiten que escape de la
        atmósfera): los forzantes y los retroalimentadores. Los gases forzantes del cambio climático son aquellos de
        larga vida que se encuentran semi-permanentemente en la atmósfera y no responden física o químicamente a los
        cambios de temperatura. Los retroalimentadores, por su parte, son aquellos que sí responden física o
        químicamente a los cambios de temperatura.
      </p>
      <p>Los gases de efecto invernadero más frecuentes en la atmósfera son los siguientes:</p>
      <p>
        <ul>
          <li>
            <b>El vapor de agua (H2O)</b> es un gas retroalimentador del cambio climático, de modo que además de ser el
            gas de efecto invernadero más abundante de la atmósfera, incrementa con el calentamiento de la misma. No
            solo esto, también aumenta la probabilidad de precipitaciones.
          </li>
          <li>
            <b>El dióxido de carbono (CO2)</b> a pesar de no ser muy abundante en la atmósfera juega un papel muy
            importante en el calentamiento global puesto que es un gas forzante del cambio climático que permanece
            largos periodos de tiempo en la atmósfera. Este gas puede ser liberado en procesos naturales como la
            respiración o las erupciones volcánicas pero las principales causas del aumento de este gas en la atmósfera
            son antropogénicas, como la quema de combustibles fósiles, los cambios en los usos del suelo o la
            deforestación.
          </li>
          <li>
            <b>El metano (CH4)</b>, a pesar de estar menos presente que el CO2 en la atmósfera, es un gas de efecto
            invernadero de larga duración mucho más potente. Este gas hidrocarburo puede ser producido de forma natural
            o a causa de la actividad humana, como por ejemplo mediante la agricultura, la ganadería o la descomposición
            de residuos orgánicos en vertederos.
          </li>
          <li>
            <b>El óxido nitroso (N2O)</b>, no solo es la principal sustancia responsable de la destrucción de la capa de
            ozono, sino que también es el tercer gas de efecto invernadero más importante después del CO2 y el CH4. Este
            gas es consecuencia de la quema de combustibles fósiles y biomasa, la producción de ácido nítrico, el uso de
            fertilizantes…
          </li>
          <li>
            <b>Los clorofluorocarbonos (CFC)</b>, a pesar de estar altamente regulados por sus implicaciones en la
            destrucción de la capa de ozono, también son gases forzantes del cambio climático de origen industrial.
          </li>
        </ul>
      </p>
      <h4>Causas naturales / ¿Por qué el calentamiento global no es un fenómeno natural?</h4>
      <img src="/text-images/otea-figure-10.png" />
      <p>
        El Sol es la fuente fundamental de energía del sistema climático, por lo que la variabilidad solar ha
        desempeñado un papel fundamental en los cambios climáticos pasados. De hecho, se cree que una disminución en la
        producción de energía solar junto con un aumento en la actividad volcánica desencadenó la Pequeña Edad de Hielo
        (1650-1850). Por ello, es lógico pensar que los cambios en la radiación solar pueden estar causando el cambio
        climático actual de forma natural. No obstante, son numerosas las evidencias que muestran que el calentamiento
        global actual no se puede explicar mediante los cambios en la energía solar:
      </p>
      <p>
        <ul>
          <li>La energía media solar que llega a la Tierra se ha mantenido relativamente constante desde 1750.</li>
          <li>
            A la vez que la temperatura aumentaba, la proporción de energía solar que llega a nuestro planeta ha seguido
            el ciclo solar natural de 11 años, con pequeños descensos y ascensos y sin un incremento neto desde mediados
            del siglo XX. Por lo tanto, es muy improbable que el Sol sea el causante del cambio climático.
          </li>
          <li>
            Si la actividad solar fuera la causante del cambio climático, las temperaturas deberían aumentar en todas
            las capas de la atmósfera. No obstante, las evidencias nos muestran que la capa superior de la atmósfera se
            está enfriando mientras la superficie y las capas inferiores tienden a calentarse a un ritmo más acelerado,
            debido a que los gases de efecto invernadero atrapan el calor en esas zonas y no dejan que escape.
          </li>
        </ul>
      </p>
      <h4>El papel de la actividad humana en el cambio climático</h4>
      <p>
        En este sentido, los expertos señalan la revolución industrial como el periodo en el que el ser humano comienza
        a influir más significativamente en el calentamiento global. Esto se debe a que las emisiones de GEI a la
        atmósfera incrementaron abruptamente, y en consecuencia la atmósfera retiene más calor del necesario de modo que
        se produce un calentamiento global. Además, la revolución industrial derivó en un nuevo modelo de producción y
        consumo.
      </p>
      <p>
        Desde entonces, los científicos afirman que hemos entrado en una nueva era geológica, marcada principalmente por
        el impacto del ser humano en el planeta: el Antropoceno. Esta nueva era ha sido propiciada por numerosos
        factores que han jugado un papel fundamental en la aceleración del calentamiento global, entre ellas:
      </p>
      <p>
        <ul>
          <li>El aumento en la demanda y producción de energía proveniente del uso de combustibles fósiles.</li>
          <li>
            El crecimiento de la población mundial, que supone un mayor empleo de recursos, y en consecuencia un aumento
            de las emisiones GEI en los procesos de producción.
          </li>
          <li>
            La destrucción de sumideros de CO2. Por ejemplo, mediante la deforestación o la destrucción de ecosistemas
            marinos que absorben hasta el 50% del CO2 antropogénico.
          </li>
        </ul>
      </p>
      <p>
        <small>
          [1] IPCC. (2014). Fifth Assesment Report. Switzerland: Intergovernmental Panel on Climate Change.
          https://www.ipcc.ch/assessment-report/ar5/
        </small>
      </p>
      <h3>Efectos</h3>
      <img src="/text-images/otea-figure-11.png" />
      <p>
        Los impactos del cambio climático abarcan un espectro muy amplio, puesto que este afecta tanto a los sistemas
        naturales como a los humanos. Estos sistemas están directamente relacionados y el cambio climático altera las
        complejas interacciones existentes entre ellos. Así, ahondaremos en los principales impactos del cambio
        climático teniendo en cuanta que todos ellos están íntimamente interconectados: las causas principales, es
        decir, las medioambientales, constituyen la base sobre la que luego se derivan las consecuencias tanto sociales
        como económicas.
      </p>
      <h4>Impactos ecológicos</h4>
      <p>
        El cambio climático influye directamente sobre el medioambiente, siendo el aumento de la temperatura (terrestre
        y oceánica) una de las principales consecuencias del mismo. Esto, está generando el derretimiento de los
        glaciares, el permafrost y el hielo del Ártico y la Antártida; y en consecuencia se está produciendo una subida
        del nivel del mar. Esto puede afectar gravemente las áreas costeras y a las islas, de hecho, ya han desaparecido
        5 islas de las Salomón y otras 6 han perdido hasta el 62% de su superficie[1].
      </p>
      <p>
        Además, los cambios en los diferentes ecosistemas marinos (como el aumento de la temperatura o la acidificación)
        tiene graves consecuencias sobre la flora y la fauna que los habitan. Muchas de estas especies, necesitan unas
        condiciones de vida muy específicas, de lo que se deriva la fragilidad de estos ecosistemas. Uno de los casos
        más impactantes en este ámbito, lo representa la degradación que está sufriendo la gran barrera de coral. Con un
        aumento de apenas 1ºC sobre la temperatura habitual, prácticamente una cuarta parte del arrecife ha muerto a
        causa del denominado efecto blanqueo masivo, fenómeno que no parece que vaya a reducirse dada la tendencia
        actual[2].
      </p>
      <p>
        Más de tres cuartas partes de la superficie del planeta está cubierta por los océanos, los que contienen el 97%
        del agua de todo el globo. El restante 3%, hace referencia al agua dulce, de la que más del 69% está congelada,
        por lo que la disponible para el uso humano es ínfima[3]. Debido a los cambios en los patrones de precipitación
        que está causando el cambio climático, hay zonas donde la cantidad de agua dulce está mermando, afectando
        directamente a los ecosistemas acuáticos y los dependientes de estos.
      </p>
      <p>
        Por otro lado, también se están multiplicando los fenómenos meteorológicos extremos (sequias, olas de calor,
        inundaciones, tormentas de nieve, huracanes…) que amenazan especialmente a los países menos desarrollados.
        Además, el aumento de las temperaturas también es el causante de muchos de los incendios y procesos de
        desertificación que ya han comenzado.
      </p>
      <p>
        Todos estos cambios en los ecosistemas y la consecuente alteración de las condiciones de vida de las especies
        que los forman y habitan, están en condiciones de cada vez mayor vulnerabilidad, lo que, en su extremo, está
        derivando en una gran pérdida de biodiversidad. Muchas especies contraen enfermedades o mueren, y se producen
        migraciones masivas en aquellas especies que tienen capacidad para ello.
      </p>
      <h4>Impactos sociales</h4>
      <p>
        En lo relativo a los impactos sociales, las consecuencias sobre la salud humana es una de las cuestiones que más
        interés está despertando, de hecho, a pesar de los avances médicos, se estima que entre 2030 y 2050 anualmente
        se producirán 250000 defunciones adicionales a causa de los efectos del cambio climático (WHO, 2014). Si bien
        las olas de calor y frío tienen graves consecuencias debido a que pueden llegar a agravar las enfermedades
        cardiovasculares y respiratorias o incluso a causar la muerte, el cambio climático intensificará las
        consecuencias puesto que serán más severas y de periodo más prolongado[4]. A pesar de que se espera una
        disminución de las muertes relacionadas con el frio en algunas regiones, el aumento de fallecimientos producidos
        por las olas de calor superará con creces esta reducción. En este sentido, si queremos evitar situaciones como
        la acaecida en 2003, cuando más de 70000 personas murieron en Europa por la incidencia de las olas de calor[5],
        es imprescindible poner en marcha planes de prevención ante temperaturas extremas.
      </p>
      <p>
        Además, las emisiones de GEI antrópicas tienen un impacto negativo en la calidad del aire. La contaminación
        llega a nuestros pulmones y sangre, causando enfermedades como asma, enfermedades cardiovasculares o cáncer. En
        este sentido, ya en 2016 se estimaba que la contaminación atmosférica provocaba más de 4,2 millones de muertes
        prematuras cada año, de las cuales el 91% pertenecían a países de bajos y medianos ingresos[6].
      </p>
      <p>
        El aumento de temperaturas también incrementara el número de vectores (como los mosquitos) y el rango de
        extensión geográfico y temporal de los mismos, que portan enfermedades infecciosas como la malaria, el dengue o
        la fiebre amarilla. Un claro ejemplo de ello se encuentra en China con la esquistosomiasis, donde se espera que
        esta enfermedad transmitida por los caracoles se propague por zonas ahora libres de ella (Zhou, 2008).
      </p>
      <p>
        Los cambios que se están produciendo en el clima (como el cambio en los patrones de precipitación) unido al
        imparable aumento poblacional, han hecho que ya a día de hoy el 80% de la población mundial este bajo la
        terrible amenaza de falta de agua de calidad para consumo humano. En este caso, la principal amenaza sobre la
        disponibilidad de agua la constituye el aumento de la población y su desigual distribución. A pesar de ello, la
        alteración humana del clima está recrudeciendo este problema en algunas zonas. No solo esto, el cambio climático
        está afectando de forma severa sobre la calidad de los recursos hídricos disponibles para los asentamientos
        humanos (Jiménez Cisneros, 2014).
      </p>
      <p>
        Por otro lado, hemos de destacar que el aumento de la temperatura conlleva mayores dificultades para erradicar
        la pobreza, reducir las desigualdades y asegurar el bienestar humano. Y es que el cambio climático puede llegar
        a ser un multiplicador de la pobreza: aumentando el número de pobres y haciendo que las personas pobres lo sean
        aún más (Hallegatte y Rozenberg, 2017). No obstante, la severidad de estos impactos será mucho mayor en las
        áreas urbanas y algunas regiones rurales de África subsahariana y el sudeste asiático (IPCC, 2018).
      </p>
      <p>
        Los impactos de 1,5°C afectarían de manera desproporcionada a las poblaciones desfavorecidas y vulnerables a
        través de la inseguridad alimentaria, el aumento de los precios de los alimentos, la pérdida de ingresos, la
        pérdida de oportunidades de subsistencia y los impactos adversos para la salud. Todo ello podría conllevar tanto
        a migraciones y desplazamientos masivos de la población, como el inicio de graves conflictos bélicos[7].
      </p>
      <h4>Impactos económicos</h4>
      <p>
        Además, el cambio climático puede afectar sectores económicos clave como el de la energía, el turismo o el
        transporte; ya sea a través de impactos directos en las operaciones o de impactos en la oferta y la demanda, con
        los riesgos que varían significativamente según la región geográfica, la estación y el tiempo (IPCC, 2018).
      </p>
      <p>
        El clima es un factor importante para determinar la geografía y la estacionalidad de la demanda y el gasto
        turístico a nivel mundial. Es por ello que las implicaciones del cambio climático para el sector turístico
        global son de gran alcance e impactan en las inversiones del sector, los activos de destino (medioambiente y
        cultural), los costos operativos y de transporte, y los patrones de demanda turística[8]. En este sentido, el
        IPCC advierte en su último informe de que el aumento de las temperaturas afectará directamente a los mercados de
        turismo dependientes del clima, como el turismo de sol, playa y deportes de nieve. Por ejemplo, el cambio
        climático tendrá un efecto especialmente negativo en el turismo del sur de Europa, esperándose una reducción del
        8% en España (Jacob et. al, 2018). No obstante, el impacto será mucho mayor en las zonas tropicales y en algunos
        estados insulares en desarrollo en los que el sector turístico representa la base económica del país.
      </p>
      <p>
        No solo esto, el cambio climático también puede afectar directamente al sector energético. A pesar de que se
        proyecta un gran aumento en la demanda de aire acondicionado, especialmente en las regiones tropicales y
        subtropicales (IPCC, 2018), el aumento de las temperaturas disminuirá la eficiencia térmica de las tecnologías
        de generación de energía fósil, nuclear, biomasa y solar, así como la de los edificios y otras infraestructuras
        (Arent et al., 2014). Estos impactos pueden afectar significativamente en ciertos países. Por ejemplo, en
        Etiopía, los gastos de capital hasta 2050 pueden aumentar hasta en un 4% en un escenario de sequía severa (Block
        y Strzepek, 2012), y en Estados Unidos el daño económico puede ascender a 1,2% del costo del PIB por año por
        aumento de 1°C (Hsiang et al., 2017).
      </p>
      <p>
        Por otro lado, el transporte por carretera, aéreo, ferroviario, marítimo y por tuberías puede verse afectado
        directa o indirectamente por el clima: por los aumentos de precipitación y de temperatura, por los
        acontecimientos climáticos extremos (inundaciones y tormentas) o por la incidencia de los ciclos de congelación
        y descongelación, entre otros (Arent et al., 2014).
      </p>
      <p>
        Finalmente, sectores como la agricultura, la ganadería o la pesca también pueden verse especialmente afectados
        por el cambio climático. A pesar de que todas estas actividades están íntimamente relacionadas con el clima, el
        caso de la agricultura es un tanto particular, ya que en su producción las temperaturas y precipitaciones tienen
        una incidencia todavía mayor. En el caso de la ganadería y la pesca, hay otros factores no directamente
        relacionados con el clima como la sobrepesca o los aportes alimenticios al ganado, que pueden mediar con las
        consecuencias negativas del clima. Aun y todo, el impacto resultante en los ecosistemas tiene consecuencias más
        que considerables como podría ser la pérdida de biodiversidad de los ecosistemas acuáticos lo que supone un
        descenso significativo de las capturas y en consecuencia de los ingresos económicos de los pescadores.
      </p>
      <p>
        En conclusión, si no se mitigan los efectos del cambio climático, al reducirse los ingresos globales promedio y
        al ampliarse la desigualdad de ingresos global, la economía global podría ser reconfigurada (Burke et al.,
        2015b).
      </p>
      <p>
        <small>
          [1] Albert, S., Leon, J., Grinham, A., Church, J., Gibbes, B., & Woodroffe, C. (2016). Interactions between
          sea-level rise and wave exposure on reef island dynamics in the Solomon Islands. Environmental Research
          Letters.
        </small>
      </p>
      <p>
        <small>
          [2] UNEP. (26 de April de 2016). Bleaching of Great Barrier Reef corals worst ever, scientists say. Obtenido
          de unenvironment.org: https://www.unenvironment.org/zhhans/node/283
        </small>
      </p>
      <p>
        <small>
          [3] USGS. (s.f.). Where is Earth's Water? Obtenido de usgs.gov:
          https://www.usgs.gov/special-topic/water-science-school/science/whereearths-water?qt-science_center_objects=0#qt-science_center_objects
        </small>
      </p>
      <p>
        <small>
          [4] Linares Gil, C., Ortiz Burgos, C., Diaz jimenez, J., & Carmona Alferez, R. (2017). Temperaturas extremas y
          salud. Cómo nos afectan las olas de calor y de frío. Madrid: Catarata.
        </small>
      </p>
      <p>
        <small>
          [5] Robine, J.-M. (2008). Death toll exceeded 70,000 in Europe during the summer of 2003. Comptes Rendus
          Biologies, 171-178.
        </small>
      </p>
      <p>
        <small>
          [6] OMS. (2 de Mayo de 2018). Calidad del aire y salud. Obtenido de Organizacion Mundial de la Salud:
          https://www.who.int/es/news-room/fact-sheets/detail/ambient-%28outdoor%29-air-quality-and-health
        </small>
      </p>
      <p>
        <small>
          [7] IPCC. (2018). Impacts of 1.5ºC Global Warming on Natural and Human Systems. En IPCC, Global Warming of
          1.5°C. An IPCC Special Report on the impacts of global warming of 1.5°C above pre-industrial levels and
          related global greenhouse gas emission pathways, in the context of strengthening the global response to the
          threat of climate change, sustainable development, and efforts to eradicate poverty. Switzerland:
          Intergovernmental Panel on Climate Change.
        </small>
      </p>
      <p>
        <small>
          [8] Scott, D., & Gössling, S. (2018). Tourism and Climate Change Mitigation. Embracing the Paris Agreement:
          Pathways to Decarbonisation. European Travel Commission. Brussels: European Travel Commission.
        </small>
      </p>
      <h3>Soluciones</h3>
      <p>
        A la hora de plantear soluciones para abordar el cambio climático, es necesario entender el alcance de la
        responsabilidad de cada sector en el agravamiento del cambio climático así como conocer el potencial de cada
        sector para revertir la problemática. Tras un exhaustivo análisis, el Programa de Naciones Unidas para el Medio
        Ambiente (PNUMA) comparte esta información y define una hoja de ruta con las actuaciones necesarias en los seis
        sectores clave para la transición hacia una economía neta en carbono.
      </p>
      <p>
        Así, estiman que para evitar alcanzar el aumento de temperatura de 1,5 grados con respeto a los niveles
        preindustriales y por ende evitar los peores efectos del cambio climático, las emisiones deben reducirse entre
        29 y 32 GT de CO2 anualmente. Para lograr este objetivo no será suficiente con reducir las emisiones directas
        del transporte y la industria, además todos los países deberán trabajar en manejar de forma eficiente la tierra
        y los recursos naturales, construir ciudades climáticamente inteligentes y reducir la deforestación y el
        desperdicio alimentario.
      </p>
      <h4>Capacidad de reducción de emisiones por sectores</h4>
      <img src="/text-images/capacidad-reduccion-emisiones.png" width="356px" />
      <p>
        Se ha evaluado que cada sector de actividad tiene la capacidad de reducir sus emisiones en determinadas
        condiciones. Para cada sector, las reducciones están vinculadas a las medidas que deben aplicar los gobiernos,
        el sector privado y los ciudadanos. A continuacion, la tabla y la infografía resumen las medidas que deben
        adoptarse a todos los niveles en cada sector
      </p>
      <img src="/text-images/otea-figure-12.png" />
      <p>
        El <b>sector energético</b> puede reducir los gases de efecto invernadero en <b>12.5GT</b> anualmente, sin tener
        que desarrollar nuevos inventos, ya que cuenta con la tecnología necesaria para reducir una gran cantidad de GEI
        transitando a las energías renovables y siendo más eficiente con la energía que consume.
      </p>
      <p>
        La <b>industria</b> puede reducir sus emisiones en <b>7.3GT</b> anualmente adoptando sistemas de refrigeración y
        calefacción pasivos o basados en energías renovables, mejorando su eficiencia energética y abordando otros
        problemas urgentes como las fugas excesivas de metano.
      </p>
      <p>
        El <b>sector de la agricultura y el sector alimentario</b> pueden reducir las emisiones en <b>6.7GT</b> anuales
        y es que reduciendo la pérdida y el desperdicio de comida y transitando hacia una dieta más sostenible se pueden
        reducir las emisiones de GEI en más de 2GT. De hecho, adoptar una alimentación rica en plantas y vegetales es
        una de las acciones clave para reducir la huella de carbono personal.
      </p>
      <p>
        En el ámbito de los <b>bosques y el uso de la tierra</b>, la capacidad de reducción de emisiones asciende a{' '}
        <b>5.3GT</b> anuales a nivel mundial si se reduce la deforestación a la mitad y se restauran los ecosistemas
        degradados. Las inversiones en este tipo de actuaciones no solo ayudarán a mitigar el cambio climático, sino que
        contribuirán a aumentar la resiliencia climática de los ecosistemas, mejoraran la calidad del aire, aumentarán
        los suministros de agua en las ciudades, aumentan la biodiversidad, reforzarán la seguridad alimentaria y
        mejorarán las economías rurales.
      </p>
      <p>
        Las emisiones del sector del <b>transporte</b>, que son responsables de una cuarta parte de las emisiones
        globales y que se duplicarían en un escenario de inacción climática para 2050, se pueden reducir en <b>4.7GT</b>{' '}
        anuales adoptando vehículos eléctricos y creando espacios seguros por los que poder caminar, andar en bicicleta
        o usar cualquier otro tipo de transporte no motorizado.{' '}
      </p>
      <p>
        En cuanto a las <b>ciudades</b> y al <b>sector de la construcción</b>, se calcula que pueden contribuir a la
        reducción de emisiones de GEI en <b>5.9GT</b> anuales. Para ello, las infraestructuras y edificios que se
        construyan deben ser bajas o netas en carbono, ya que se estima que el 70% de la infraestructura urbana
        necesaria para adaptarse a un mundo en rápido crecimiento está todavía por construir.
      </p>
      <h4>Hoja de ruta y líneas de acción</h4>
      <p>
        A continuación se detallan las medidas que deben adoptar tanto los gobiernos, como el sector privado y la
        ciudadanía para alcanzar las potenciales reducciones de emisiones estimadas para cada uno de los sectores.
      </p>
      <table>
        <tr>
          <th />
          <th>Gobiernos</th>
          <th>Sector privado</th>
          <th>Ciudadanía</th>
        </tr>
        <tr>
          <td>Energia</td>
          <td>
            <ul>
              <li>
                Definir objetivos de descarbonización y estrategias nacionales de transición energética más ambiciosas.
              </li>
              <li>Abandonar las políticas y subsidios que apoyan a la industria de los combustibles fósiles.</li>
              <li>Implementar políticas para incentivar la energía renovable y promover la eficiencia energética.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Definir objetivos de descarbonización.</li>
              <li>Auditar el uso de la energía y la eficiencia energética de las empresas.</li>
              <li>
                Aprovechar las oportunidades que ofrecen las energías renovables en todas las cadenas de suministro.
              </li>
              <li>Planificar e implementar una estrategia de desinversión en combustibles fósiles.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Entender cuánta energía utiliza y tratar de reducir el consumo.</li>
              <li>Usar energía que provenga de fuentes renovables.</li>
              <li>Eliminar las inversiones en combustibles fósiles.</li>
              <li>Decantarse por proveedores de energía publicos y comprometidos con la sostenibilidad.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Industria</td>
          <td>
            <ul>
              <li>Fortalecer los estándares de eficiencia energética.</li>
              <li>
                Poner precio al carbono para reducir las tecnologías intensivas en carbono y promover alternativas
                sostenibles.
              </li>
              <li>Fomentar el uso de calefacción y refrigeración eficiente y renovable.</li>
              <li>Incentivar el uso eficiente de GEI, incluyendo la reducción de fugas de metano.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Aumentar la investigación y el desarrollo de nuevos procesos industriales bajos en carbono.</li>
              <li>Auditar el uso de la energía y la eficiencia de los recursos para definir líneas de acción.</li>
              <li>Entender la exposición al riesgo climático.</li>
              <li>Aprovechar las oportunidades asociadas a las renovables y a la eficiencia de los recursos.</li>
              <li>Ser un líder en prácticas industriales sostenibles</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Reducir, reutilizar, reparar y reciclar lo que se consume.</li>
              <li>
                Solicitar a los gobiernos y a las empresas información sobre producción, compromisos y prácticas
                sostenibles con el medioambiente.{' '}
              </li>
              <li>Preguntar a organismos gubernamentales y empresas sobre opciones sostenibles.</li>
              <li>Apoyar a las empresas que adoptan prácticas sostenibles y circulares.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Agricultura y alimentación</td>
          <td>
            <ul>
              <li>Medir, crear una línea de base y reducir el desperdicio de alimentos.</li>
              <li>
                Definir y promover objetivos basados en los conocimientos científicos para aumentar la disponibilidad y
                absorción de alimentos ricos en plantas, para aumentar la producción sostenible y para minimizar la
                pérdida de alimentos.
              </li>
              <li>
                Informar a la ciudadanía y a las empresas o personas productoras sobre la elección de alimentos y la
                reducción del desperdicio.
              </li>
              <li>Alinear las recomendaciones alimentarias nacionales con los objetivos climáticos.</li>
              <li>Promover una agricultura sostenible y climáticamente inteligente.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Medir y reportar el desperdicio de alimentos.</li>
              <li>
                Comprometerse en la reducción del desperdicio alimentario con objetivos de reducción de al menos la
                mitad para 2030.
              </li>
              <li>
                Trabajar junto con proveedores y clientes en la búsqueda de soluciones para la reducción del desperdicio
                de alimentos a lo largo de toda la cadena de suministro.
              </li>
              <li>
                Revisar el embalaje/empaquetado, proporcionar una guía clara de almacenamiento, eliminar las fechas de
                “exhibición hasta” y aclarar las fechas de caducidad o antes de usar.
              </li>
              <li>
                Evitas las promociones 2x1 si es probable que las personas consumidoras compren más de lo que pueden
                comer.
              </li>
              <li>Reutilizar los ingredientes extramaduros en la tienda.</li>
              <li>
                Integrar las estrategias de pérdida de alimentos en toda la empresas, desde las personas empleadas hasta
                las consumidoras.
              </li>
              <li>
                Implementar procesos para el rescate de excedentes para transferir los alimentos sanos sobrantes a las
                personas más vulnerables.
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Cambiar a una dieta rica en plantas y vegetales.</li>
              <li>Comer alimentos de temporadas y de cercanía.</li>
              <li>Comprar sólo lo que se puede comer o ahorrar.</li>
              <li>
                Planificar las comidas y las listas de la compra, medir el tamaño de las porciones y cocinar las sobras.
              </li>
              <li>Comprar las verduras y las frutas “feas”.</li>
              <li>Almacenar los alimentos correctamente, congelandolos cuando sea necesario.</li>
              <li>Compartir los excesos con las personas más vulnerables.</li>
              <li>Compostar los restos de comida.</li>
              <li>
                Pedir a las tiendas, restaurantes y hoteles que aborden la problemática del desperdicio alimentario y
                apoyar a quienes lideran el cambio.
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Bosques y uso de la tierra</td>
          <td>
            <ul>
              <li>
                Reducir la deforestación tropical a la mitad para 2050 y detener la deforestación neta para 2030 a nivel
                mundial.
              </li>
              <li>
                Eliminar las políticas y subsidios que incentivan la deforestación y promover la restauración de bosques
                degradados.
              </li>
              <li>Vigilar y evaluar sistemáticamente el progreso de los esfuerzos de conservación y restauración</li>
              <li>
                Restaurar 150 millones de hectáreas de bosques y otros paisajes para el 2020 y 350 millones de hectáreas
                para el 2030 (objetivos principales del Desafío de Bonn).
              </li>
              <li>
                Monitorear y evaluar sistemáticamente el progreso de los esfuerzos de conservación y restauración.
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li>
                Colaborar con los proveedores para minimizar los impactos en los ecosistemas a lo largo de toda la
                cadena de suministro.
              </li>
              <li>Invertir en conservación y restauración de ecosistemas.</li>
              <li>Promover las inversiones en cadenas de suministro libres de deforestación.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>
                Unirse a una organización local o nacional que apoye la conservación y restauración del hábitat
                forestal.
              </li>
              <li>
                Adoptar una dieta que reduzca la pérdida y degradación del hábitat forestal, comprando productos de
                temporada, de cercanía y libres de ingredientes cuya obtención contribuya a la deforestación.
              </li>
              <li>Neutralizar tu huella de carbono a través de inversiones en sumideros de carbono naturales.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Transporte</td>
          <td>
            <ul>
              <li>
                Incentivar la transición al transporte de emisiones cero, para coches, taxis, autobuses, camiones y
                trenes.
              </li>
              <li>
                Invertir y eliminar las barreras a infraestructuras de movilidad no motorizada como los carriles
                protegidos para bicicletas.
              </li>
              <li>
                Promover políticas que reduzcan las emisiones del transporte y aportan importantes beneficios a la salud
                pública, incluyendo un mayor transporte público y la movilidad no motorizada.
              </li>
              <li>Cambiar las flotas a vehículos eléctricos.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Cambiar al ferrocarril para el transporte de materias primas.</li>
              <li>Cambiar las flotas a vehículos eléctricos.</li>
              <li>Adoptar las videoconferencias para reuniones y conferencias.</li>
              <li>Organizar modalidades de trabajo flexibles y escalonadas.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Comprar vehículos eléctricos y coches que utilicen combustibles más limpios.</li>
              <li>Elegir el tren en lugar del avión y viajar lo menos posible.</li>
              <li>Reducir los desplazamientos trabajando desde casa.</li>
              <li>Realizar reuniones por videoconferencia.</li>
              <li>unirse a los servicios de uso compartido de bicicletas, scooters o automoviles.</li>
              <li>
                Apoyar las iniciativas del gobierno local para mejorar las infraestructuras de transporte público y
                movilidad no motorizada.
              </li>
            </ul>
          </td>
          <td>
            <td>Ciudades y edificios</td>
            <td>
              <ul>
                <li>Modernizar los edificios públicos.</li>
                <li>
                  Promover la instalación de bombas de calor, células solares y tecnología de almacenamiento de calor.
                </li>
                <li>
                  Incentivar la instalación de refrigeración y calefacción central y el uso de electrodomésticos y luces
                  de bajo consumo.
                </li>
                <li>Establecer estándares de construcción de carbono neutral para las nuevas construcciones.</li>
                <li>Integrar la planificación sostenible en la planificación urbana y rural.</li>
                <li>
                  Incentivar las soluciones de miniredes, la calefacción y refrigeración de distrito y los sistemas de
                  residuos en la energía.
                </li>
                <li>
                  Planificar la densidad estratégica y el uso mixto de edificios y tejido urbano de las ciudades, de
                  modo que los barrios tengan los servicios que necesitan a escala local.
                </li>
                <li>
                  Integrar la infraestructura gris, azul y verde para administrar los recursos y la escorrentía con un
                  impacto mínimo en el medio ambiente.
                </li>
                <li>
                  Invertir en infraestructura física y de mercado para vincular mejor a los y las productoras y los y
                  las consumidoras rurales y urbanas.
                </li>
                <li>
                  Desarrollar sistemas inteligentes para integrar edificios, sistemas de movilidad y energía, incluida
                  la gestión del tráfico, la carga distribuida de vehículos eléctricos y los procesos de planificación
                  integrados.
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Evaluar el uso de la energía y la huella de carbono.</li>
                <li>Incluir la sostenibilidad en las prácticas comerciales y de inversión a largo plazo.</li>
                <li>
                  Aprovechar los incentivos gubernamentales diseñados para reducir las emisiones de gases de efecto
                  invernadero.
                </li>
                <li>Ser líder en prácticas sostenibles y bajas en carbono</li>
                <li>Educar a las personas consumidoras y a las y los clientes sobre la sostenibilidad.</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Comprender y analizar la eficiencia energética de una casa antes de comprar o alquilar.</li>
                <li>Descubrir donde pierde energía tu hogar y ponerle solución.</li>
                <li>
                  Sellar ventanas y puertas, detener los puentes térmicos a través del aislamiento, instalar puertas de
                  doble acristalamiento e invertir en bombas de calor.
                </li>
                <li>
                  Usar cortinas y persianas para mantener la casa fresca y reducir la necesidad de aire acondicionado.
                </li>
                <li>
                  Cambiar a la iluminación LED y comprender el rendimiento de la eficiencia al comprar electrodomésticos
                  nuevos.
                </li>
              </ul>
            </td>
          </td>
        </tr>
      </table>
    </BaseLayout>
  )
}

const BaseLayout = styled(Layout)`
  height: 100% !important;
  padding: 100px 40px;
`
