import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import { MainMenu } from '../layouts/components/MainMenu'
import { Helmet } from "react-helmet";

export const EnergeticTransition: React.FC = () => {
  return (
    <BaseLayout>
      <Helmet>
        <title>La transición energética: Historia y contexto actual</title>
        <meta name="description" content="La transición energética en el mundo: Historia, situación actual y contexto." />
        <meta name="keywords" content="Cambio climático, transición ecológica, artículos, noticias, estudios, publicaciones, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MainMenu />
      <h2>LA TRANSICIÓN ENERGÉTICA</h2>
      <p>
        El Acuerdo de París implica una transformación sin precedentes del sistema energético y del modelo de
        desarrollo. Según el Grupo Intergubernamental de Expertos sobre Cambio Climático (conocido por sus siglas en
        inglés, IPCC) las emisiones globales de gases de efecto invernadero habrán de reducirse en los próximos 10 años
        un 45% respecto a 2010 y ser prácticamente cero en 2050. Para que esta transformación sea alcanzable es
        necesario un cambio acelerado del mix energético global hasta conseguir abandonar las energías fósiles y
        contaminantes y sustituirlas por energías limpias y renovables, si queremos evitar las peores consecuencias de
        la crisis climática.
      </p>
      <p>
        La transición energética hacia una sociedad libre de carbono necesita del impulso político y de la cooperación
        entre diferentes países y gobiernos en el contexto del Acuerdo de París, cuyos planes tienen que aumentar su
        grado de ambición ya que actualmente avanzamos hacia la desestabilización climática. Además, alcanzar una
        sociedad neutra en emisiones y que dé respuesta a la emergencia climática supone un reto técnico y económico, ya
        que será necesario descarbonizar todos los sectores de la economía y las empresas, lo que exige tener en cuenta
        los efectos positivos y co-beneficios pero también los riesgos asociados a la propia transición. Finalmente,
        abordar correctamente la dimensión social de la transición energética será crítico, ya que sin la participación,
        presión y apoyo de la ciudadanía difícilmente esta ocurrirá al ritmo necesario. La transición energética no sólo
        tiene que ser eficiente, es también fundamental que sea justa, de forma que las políticas de los gobiernos se
        diseñen de una forma que repartan adecuadamente los beneficios de la transición y minimicen los posibles efectos
        negativos sobre los grupos sociales o territorios más afectados o vulnerables.
      </p>
      <p>
        En los siguientes bloques se recogen de una forma técnica y rigurosa pero accesible para la ciudadanía y los
        medios de comunicación las principales o más frecuentes preguntas y respuestas asociadas a la transición
        energética.
      </p>
      <img src="/text-images/otea-figure-14.png" alt="" />
      <h3>Bloque I: Acuerdo de París y objetivos de descarbonización</h3>
      <h4>¿Qué es el Acuerdo de París y cuáles son sus objetivos?</h4>
      <p>
        El Acuerdo de París es un tratado internacional que fue adoptado en diciembre de 2015 en la 21ª Conferencia
        sobre el Cambio Climático celebrada en París (Francia) y entró en vigor el 4 de noviembre de 2016. El tratado ha
        sido firmado por 195 países y ratificado por 187.
      </p>
      <p>
        El Acuerdo de París establece un marco global para reforzar la respuesta de todos los países frente a la crisis
        climática. Se trata del primer acuerdo internacional de este tipo en el que todas las naciones tienen la causa
        común de realizar esfuerzos ambiciosos para combatir el cambio climático y adaptarse a sus efectos, y en el que
        los países desarrollados se comprometen a ayudar a los países en desarrollo en el proceso.
      </p>
      <p>Tiene 3 objetivos principales:</p>
      <ol>
        <li>
          Mantener el aumento de la temperatura media mundial muy por debajo de 2 °C con respecto a los niveles
          preindustriales, y realizar esfuerzos para limitar ese aumento de la temperatura a 1,5 °C. Para ello es
          necesario alcanzar la neutralidad climática a mediados de siglo (Articulo 4.1).
        </li>
        <li>
          Aumentar nuestra capacidad de adaptación a las consecuencias de la crisis climática y promover un tipo de
          desarrollo no dependiente de los combustibles fósiles.
        </li>
        <li>Dirigir las inversiones hacia proyectos con bajas emisiones de carbono.</li>
      </ol>
      <h4>¿Es el Acuerdo de París vinculante?</h4>
      <p>
        A diferencia del Protocolo de Kioto, los objetivos de reducción de emisiones del Acuerdo de París no son
        vinculantes. Sin embargo, el Acuerdo de París sí obliga a todos los países a realizar y mantener/actualizar sus
        compromisos (llamados NDCs) cada 5 años, a ser transparentes durante todo el proceso y realizar una
        monitorización del cumplimiento de sus objetivos. En 2020 los países tienen que enviar sus planes actualizados y
        con una mayor ambición ya que con la suma de los compromisos actuales estamos lejos de la senda del Acuerdo de
        París.
      </p>
      <h4>¿Qué es la neutralidad climática? </h4>
      <p>
        Ante la emergencia climática y para cumplir el Acuerdo de París es necesario alcanzar la neutralidad de carbono
        a mediados de siglo. La neutralidad climática se refiere a lograr emisiones netas de CO2 cero, equilibrando las
        emisiones de carbono que se puedan evitar con la compensación con absorciones de carbono mediante soluciones
        naturales (bosques y suelos) o artificiales (captura y almacenamiento geológico, CCS.). La Unión Europea, por
        ejemplo, lo ha establecido ya para 2050 en su Green New Deal. Aunque algunos estados miembros como Hungría,
        Polonia y la República Checa son más reacios, otros como Suecia tienen como objetivo alcanzar las emisiones cero
        para 2045.
      </p>
      <h4>¿Estamos en la senda de cumplir el Acuerdo de París?</h4>
      <p>
        Lamentablemente no. El último Informe de Naciones Unidas (Production Gap Report, UNEP 2019) indica que el mundo
        está produciendo mucho más carbón, petróleo y gas de lo que es consistente con los objetivos de 1.5°C o 2°C,
        creando una "brecha de producción" que hace que los objetivos climáticos sean mucho más difíciles de alcanzar.
        Además, como indica el Informe de Naciones Unidas de la “brecha” de emisiones (Emissions Gap Report UNEP 2019)
        el mundo se dirige a un aumento de temperatura de 3.2°C, con las naciones del G20 representan el 78% de todas
        las emisiones. Los países deben de triplicar las ambiciones de sus planes (NDC) para lograr el objetivo debajo
        de 2 ° C y más de cinco veces para lograr el objetivo de 1.5°C. Del mismo modo, el Informe Meteorológico Mundial
        muestra que la concentración de gases de efecto invernadero ha alcanzado un nivel récord en 2019, a pesar de
        todos los compromisos en virtud del acuerdo de París sobre el cambio climático.
      </p>
      <h4>¿Estamos a tiempo de cumplir el Acuerdo de París? </h4>
      <p>
        Según el último Informe del IPCC (IPCC 2019), aún es posible desde un punto de vista físico alcanzar el objetivo
        de limitar el aumento de temperatura en 1.5 grados centígrados, aunque cada vez es más difícil por la falta de
        acción y el aumento continuo de las emisiones. Para cumplir el objetivo del 1.5 es necesario una reducción sin
        precedentes de las emisiones de gases de efecto invernadero. Las emisiones globales deberían reducirse un 45%
        para 2030 con respecto a 2010 y alcanzar la neutralidad climática en 2050. El cumplimiento del objetivo de los 2
        grados centígrados permitiría una reducción menos drástica de las emisiones, pero también nos expondría a daños
        del cambio climático mucho mayores como ha alertado el IPCC.
      </p>
      <h4>¿Qué implicaciones tiene el Acuerdo de París sobre los combustibles fósiles?</h4>
      <p>
        Los combustibles fósiles tienen que reducirse de forma rápida del mix energético global, ya que actualmente
        suponen el 80% del consumo energético global. Según el último informe de la Agencia Internacional de la Energía
        Renovable (IRENA 2019), para cumplir al Acuerdo de Paria, el combustible que más rápidamente debería reducirse
        es el carbón, por su elevada intensidad de emisiones y por la existencia de alternativas renovables competitivas
        en el sector eléctrico. El carbón debería reducirse cerca de un 50% en 2030 y un 35% para 2030. El gas natural
        también alcanzaría el pico de demanda en 2025 aunque mantendría una mayor presencia hasta 2050.
      </p>
      <p>
        No obstante, las inversiones en nuevos proyectos de centrales de carbón en los últimos años, especialmente en
        Asia, indican que estamos lejos de estar alineados con el Acuerdo de París. En el futuro o no se cumplirá el
        Acuerdo de París o estas inversiones generan seguramente pérdidas para los propietarios. Según Carbon Tracker,
        actualmente 1.6 billones de euros en activos del sector de los combustibles fósiles quedaría varados (stranded
        assets). Solo en 2018, el sector petrolífero ha invertido 50.000 millones de euros invertidos en proyectos no
        compatibles con el Acuerdo de París.
      </p>
      <h4>¿Cuáles son los objetivos de los principales emisores: China, USA, UE e India?</h4>
      <p>
        China es el mayor emisor de gases de efecto invernadero del mundo y representa aproximadamente el 27% de las
        emisiones mundiales (excluyendo LULUCF) con una NDC "bastante insuficiente". A pesar del optimismo con las
        emisiones nivelándose entre 2014 y 2016, el consumo de combustibles fósiles ha seguido aumentado. China también
        está financiando y construyendo infraestructura fósil y de renovables en todo el mundo. De todas las plantas de
        carbón en desarrollo fuera de China, un cuarto, o 102 GW de capacidad, están financiadas por empresas chinas, lo
        que representa casi el doble de la capacidad actual de carbono de Alemania.
      </p>
      <p>
        EE. UU. el segundo emisor más grande del mundo está en proceso de retirarse del Acuerdo de París. Sin embargo,
        la salida no se finalizará hasta el día después de las elecciones de 2020. En 2018, EE. UU. superó a Rusia y
        Arabia Saudí, convirtiéndose en el mayor productor mundial de petróleo crudo. También es el mayor productor
        mundial de gas natural, y aumentó las exportaciones de GNL en un 53% en 2018. No obstante, la producción de
        electricidad con carbón ha disminuido drásticamente en los últimos años.
      </p>
      <p>
        India anunció su intención de alcanzar un objetivo de 450 GW de energías renovables para 2030, posicionándolo
        como uno de los líderes de las energías renovables. Después de adoptar su Plan Nacional de Electricidad (NEP) en
        2018, India sigue en camino de alcanzar sus objetivos de acción climática calificados como "compatibles con
        2˚C". Sin embargo, un riesgo sobre las perspectivas para India es la expansión continua del carbón, con el Plan
        (NEP) en 2018 incluyendo más de 90 GW de capacidad planificada de carbón que aumentará las emisiones y el riesgo
        financiero por activos varados (stranded assets).
      </p>
      <p>
        La UE, por su parte, es el primer gran bloque económico en proponer un objetivo ambiciosos para 2020 (-20%, y en
        vía de cumplirlo), 2030 (-50/55% respecto a 1990) y 2050 (neutralidad climática). El reto de la UE es utilizar
        su influencia para impulsar la acción en el resto de países clave como China, USA e India.
      </p>
      <h4>¿Qué implicaciones tiene la salida de Estados Unidos del Acuerdo?</h4>
      <p>
        El Presidente Trump siempre mostró su rechazo al Acuerdo de París durante su campaña electoral y su negacionismo
        del cambio climático. El 5 de noviembre de 2019 comunicó a Naciones Unidas su intención de abandonar el Acuerdo
        de París. Sin embargo, esta salida no se hará efectiva hasta un día después de las elecciones de 2020, ya que el
        Acuerdo estipula que ningún país puede salirse antes de tres años desde el comienzo de su implementación. De
        esta manera, Trump deja la puerta abierta a la continuidad de Estados Unidos en el acuerdo si cambia el
        Presidente.
      </p>
      <p>
        Aunque la declaración de intenciones actual del gobierno de Estados Unidos frente al cambio climático es muy
        grave, ya que es uno de los países que emiten más gases de efecto invernadero, afortunadamente muchos Estados
        (ej. California o Hawái) y ciudades (New York, Los Ángeles) y empresas, universidades, grupos sociales y
        religiosos dentro de EEUU que representan a 150 millones de estadounidenses y el 50% del PIB de Estados Unidos
        se han agrupado para manifestar que siguen adelante con los compromiso del Acuerdo de París y agrupados en la
        plataforma “We are still in” (“Nosotros seguimos dentro”).
      </p>
      <h4>¿Qué es la justicia climática?</h4>
      <p>
        El término “justicia climática” hace referencia a la desigualdad existente entre quienes han generado el
        problema del cambio climático y quienes más lo sufrirán. En primer lugar, no todos los países han generado las
        mismas emisiones a lo largo de la historia y, por lo tanto, los compromisos a exigir no pueden ser los mismos,
        si bien una acción climática global y conjunta es crucial. Por otra parte, la crisis climática no va a afectar
        de la misma manera o de manera uniforme a todos los lugares del mundo, ni tampoco a todas las clases sociales,
        sino que tendrán más impactos en los países más pobres y en las capas sociales más vulnerables.
      </p>
      <h4>¿Cómo se distribuye la responsabilidad histórica de las emisiones?</h4>
      <p>
        Por un lado, los países que más gases de efecto invernadero (GEI) emiten actualmente son China, Estados Unidos,
        Unión Europea e India, como muestra el Informe 2019 Global Carbon Budget. Sin embargo, China e India no han
        comenzado a emitir de manera significativa hasta los años 2000, cuando han comenzado a desarrollarse, de manera
        que su responsabilidad histórica es mucho menor. De hecho, si miramos las emisiones acumuladas a lo largo de la
        historia, vemos que Estados Unidos ha emitido el 25% de las emisiones de GEI globales, la UE un 22% y China e
        India tan solo un 13 y un 3% respectivamente (Our World In Data).
      </p>
      <h4>¿Quiénes sufrirán más las consecuencias de la crisis climática?</h4>
      <p>
        Las consecuencias del cambio climático no van a ser las mismas en todos los lugares del mundo y van a depender
        altamente del contexto de cada región. En general, las zonas del sur global, como por ejemplo la India o el
        África Subsahariana, son las zonas que van a sufrir las mayores consecuencias, es decir, zonas que escasamente
        han contribuido a crear la crisis climática. Está previsto que en estas zonas aumente de manera peligrosa la
        temperatura, las sequías sean cada vez más frecuentes y disminuya la productividad de los cultivos, tanto que
        muchas regiones pueden resultar inhabitables en unas décadas, lo que generará un efecto multiplicador sobre
        otros presiones que sufren estas poblaciones y aumentará la emigración por razones climáticas.
      </p>
      <p>
        Otro ejemplo de esta desigual distribución de las consecuencias de la crisis climática es la situación de muchas
        islas del Océano Pacífico, habitadas mayoritariamente por comunidades indígenas (Ej. Tuvalu o Kiribati), que
        puede llegar a desaparecer debido al aumento del nivel del mar sin haber contribuido al problema.
      </p>
      <p>
        Por otra parte, en cualquier parte del mundo, serán las poblaciones más vulnerables (mujeres, niños, personas
        con escasos recursos, etc.) las que se verán más afectadas por la crisis climática. Por este motivo, cuando
        tratamos el tema de la crisis climática, es necesario adoptar una visión global, darnos cuenta de la complejidad
        del problema y de cómo interactúa con muchos otros aspectos de nuestra sociedad.{' '}
      </p>
      <h3>Bloque II: Aspectos técnico-económicos</h3>
      <h4>¿Cuál es el papel de las energías renovables? ¿si son competitivas, por qué no se usan más?</h4>
      <p>
        La mayoría de estudios indican que las energías renovables aportarán una parte muy sustancial de la reducción de
        emisiones necesaria, junto con el ahorro y la eficiencia energética. La reducción de costes de estas tecnologías
        ha sido enorme en la última década, especialmente en el caso de la solar fotovoltaica (-80%) y la eólica (-50%),
        y en los últimos años también la eólica marina y la solar termoeléctrica. Según el último informe sobre de
        costes de la renovables (IRENA 2019), los costes de estas tecnologías ya están en muchos países por debajo de
        los costes (nivelados) de las opciones fósiles. Algunos estudios, como han ido más lejos (Lazard 2018), y
        señalan que en algunos casos los costes (totales) de las renovables, estarían ya por debajo de los costes de
        operación y mantenimiento de la energía nuclear.
      </p>
      <p>
        La competitividad de las energías renovables depende de lugares y sectores. Por ejemplo, es difícil sustituir la
        demanda de calor de alta temperatura de la industria con renovables, pero no así en la producción de
        electricidad, especialmente en países con elevado potencial eólico o solar. Las energías renovables están
        avanzando rápidamente pero al ser aún una parte pequeña de mix energético, sufren de falta de economías de
        escala y de las infraestructuras que sí están disponibles para las energías fósiles. Además, las energía fósiles
        no sólo no están gravadas por las externalidades/daños que generan, sino que reciben todavía y en 2019 tres
        veces más apoyo público que las renovables, según el Energy Outlook 2019 de la Agencia Internacional de la
        Energía, lo que supone una barrera muy importante para su desarrollo.
      </p>
      <h4>¿Es importante lograr un sistema eléctrico 100% renovable?</h4>
      <p>
        Alcanzar un sistema eléctrico cada vez más renovable o 100% renovable es crítico en el medio y largo plazo para
        alcanzar la neutralidad climática [IPCC 2019]. En primer lugar, porque las emisiones del sector eléctrico son
        una parte importante de las emisiones del sistema energético. Y, en segundo lugar, porque la descarbonización de
        algunos sectores podría realizarse mediante la electrificación de su mix energético, lo que también reduciría
        las emisiones indirectamente en otros sectores. Un ejemplo es el transporte de pasajeros donde se podrían
        sustituir los vehículos de motor de combustión por vehículos eléctricos propulsados por electricidad, y que a su
        vez estaría impulsados por la energía del sol, viento o agua y otro ejemplo son las calefacciones o calentadores
        de agua de gas de los hogares, que podría estar basado en electricidad
      </p>
      <h4>¿Es técnicamente viable un sistema eléctrico 100% renovable?</h4>
      <p>
        Conseguir un sistema eléctrico 100% renovable, es decir, aquel que no utiliza ninguna fuente de energía fósil o
        nuclear, es un reto importante ya que la energía renovable es variable y no se corresponde siempre con el perfil
        de la demanda. Para corregir este desfase entre generación y demanda, será cada vez más crítico el rol de los
        sistemas almacenamiento, así como reforzar otros elementos como la gestión de la demanda o las interconexiones.
      </p>
      <p>
        Encontramos varios países cuyo sistema eléctrico produce más de la mitad a partir de fuentes renovables como por
        ejemplo Costa Rica, Islandia y Paraguay [IEA 2019]. Además de casos reales, muchos estudios científicos van en
        el sentido que un sistema eléctrico 100% renovable viable es factible, incluso con precios más bajos de la
        electricidad [Brown 2018].
      </p>
      <h4>¿Cuál es el rol de almacenamiento?</h4>
      <p>
        En un sistema eléctrico o energético 100% renovable el papel de almacenamiento será crítico. Entre los sistemas
        de almacenamiento existentes, el bombeo, la energía hidráulica y las baterías son las tecnologías que ya han
        logrado madurez tecnológica y que se pueden integrar en los sistemas energéticos (IRENA2017). Estos sistemas de
        almacenamiento permiten integrar actualmente las energías renovables y añadir flexibilidad al sistema para que
        se pueda adaptar a la variabilidad de la generación de las renovables. Sin embargo, el rol del almacenamiento
        necesita todavía de un apoyo político importante para poder desarrollarse (Comisión Europea 2017).
      </p>
      <h4>¿Cuál es el papel del ahorro y la eficiencia energética? ¿Y las principales barreras?</h4>
      <p>
        El ahorro y la eficiencia energética serán fundamentales. Según la Agencia Internacional de la Energía Renovable
        (IRENA 2019) será junto con las energías renovables las medidas que más contribuirán a alcanzar los objetivos
        del Acuerdo de París. En concreto el ahorro y eficiencia aportaría el 25% de la reducción de emisiones y las
        renovables el 39%. Muchas de estas medidas además, aunque requieren una inversión inicial, suponen un ahorro
        económico neto con el tiempo, y por lo tanto son medidas de coste cero o incluso negativo para reducir
        emisiones. Sin duda, existen muchas oportunidades para ahorrar energía (consumir menos) y mejorar la eficiencia
        energética (mejorar la relación entre la energía consumida y los servicios energéticos obtenidos). Por ello, es
        uno de los principales objetivos de la Comisión Europea, (Directiva 2018 y 2012/27/EU y Paquete de Invierno) que
        incluye medidas de autoconsumo, vehículo eléctrico, urbanismo, transporte, generación distribuida y edificios de
        energía casi nula.
      </p>
      <p>
        Una de las principales barreras a las que se enfrentan los objetivos de ahorro y eficiencia es el efecto rebote
        (paradoja de Jevons) ya que a mayor eficiencia mayor podría ser el consumo recurso (por el desarrollo
        tecnológico o la introducción de políticas favorables, por ejemplo), Otra barrera importante se refiere a la
        incertidumbre o largo plazo de la rentabilidad de las inversiones en materia de eficiencia energética. Además,
        hay barreras financieras, culturales que dificultan la interiorización de las ventajas del ahorro energético al
        consumidor o incentivos asimétricos.
      </p>
      <h4>¿Cuáles son las perspectivas futuras de la energía nuclear a nivel global dados sus costes?</h4>
      <p>
        La energía nuclear es una energía baja en carbono, ya que no produce gases de efecto invernadero. Sin embargo,
        su uso no se ha incrementado significativamente desde los años 90, mientras que el uso de otras energías bajas
        en carbono, como eólica y solar, se ha incrementado rápidamente en la última década. Aunque las decisiones
        políticas han influido en estas tendencias, el factor más importante ha sido la evolución de los costes. Desde
        2009, el coste de la energía nuclear se ha incrementado ligeramente, mientras que el coste de la energía eólica
        se ha reducido por 3 (es ahora un tercio de la de entonces), y el de energía solar por 9. Gracias a estos
        desarrollos, el coste de producir un kilovatio-hora con nuevas instalaciones de energía nuclear es más alto que
        producir la misma cantidad con nuevas instalaciones de eólica o solar. Se prevé que esta tendencia va a
        continuar en las siguientes décadas.
      </p>
      <h4>¿Cuál es el papel de los coches eléctricos y las barreras? ¿emiten menos CO2?</h4>
      <p>
        La electrificación del transporte es fundamental para reducir las emisiones de GEI y las de otros contaminantes.
        Además, la integración de los vehículos eléctricos en la red eléctrica puede impulsar un mayor despliegue de
        tecnologías renovables, ya que los picos de generación de las renovables (principalmente de energía solar
        fotovoltaica y eólica) pueden ser aprovechados para recargar los VE.
      </p>
      <p>
        Las emisiones de un vehículo eléctrico, incluso teniendo en cuenta las emisiones totales durante la vida útil,
        son siempre inferiores a las de uno de combustión interna. Como media en la UE son un 55% inferiores frente a
        las de un Diésel y en España son un 60%.
      </p>
      <p>
        El coste inicial del vehículo, superior al de uno de combustión interna, ha sido una de las mayores barreras
        para su despliegue a escala global. Según distintos agentes (como BNEF), se espera que entre 2025 y 2030 los
        vehículos eléctricos alcancen paridad de costes con su análogo de combustión interna. Si se tienen en cuenta los
        costes durante toda la vida útil, los vehículos eléctricos ya son más baratos.{' '}
      </p>
      <h4>¿Cuál es rol de la reforestación y la captura/CCS para lograr la neutralidad climática?</h4>
      <p>
        Uno de los factores clave para poder alcanzar la neutralidad climática son los denominados sumideros de carbono.
        Este término se utiliza para reflejar los procesos mediante los cuales el carbono de la atmósfera es absorbido
        por depósitos naturales o artificiales y se reduce la concentración de CO2 en la atmósfera. Además, y dado que
        algunas emisiones son difícilmente evitables por las características de sus procesos (ej. cemento, agricultura,
        etc.) el aumento de los sumideros de carbono puede ayudar a compensar esas emisiones y alcanzar las neutralidad
        climática.
      </p>
      <p>
        En el contexto del Acuerdo de París de alcanzar la neutralidad climática a mediados de siglo existe un gran
        debate alrededor del potencial y el protagonismo que deben tener los sumideros de carbono. Muchos sectores de la
        industria han propuesto estrategias basadas en el secuestro de carbono basados en la naturaleza para evitar una
        reducción drástica de las emisiones en la producción. Sin embargo, la implementación de estas técnicas a una
        escala suficiente para reducir de manera significativa la concentración de CO2 atmosférico supondría un impacto
        muy significativo en el uso del suelo con consecuencias negativas para la biodiversidad y los servicios
        ecosistémicos (Smith, 2018).
      </p>
      <p>
        El principal mecanismo de secuestro de carbono es la fotosíntesis que tiene lugar en los bosques y en los
        océanos y estos constituyen los principales sumideros naturales. Estudios recientes apuntan a la idea de plantar
        millones de árboles en todo el mundo como una de las formas más efectivas de eliminar hasta dos tercios de las
        emisiones de CO2 producidas por actividades humanas sin ocupar las tierras de
      </p>
      <h3>Bloque III: Aspectos socio-económicos</h3>
      <h4>¿La transición energética es un coste o será una oportunidad?</h4>
      <p>
        La transición energética puede ser una oportunidad a nivel global, muy especialmente para aquellos países que
        cuentan con una alta dependencia exterior hacia los combustibles fósiles y que además tienen un elevado
        potencial de recursos renovables. Según IRENA (2019), el cumplimiento del Acuerdo de París aumentará el empleo a
        nivel global un +0.2% respecto al tendencial, lo que supone unos 7 millones de empleos adicionales a nivel
        global. El PIB aumentaría un 2,5% en los países de la OCDE para 2050, resultados muy similares a los obtenidos
        por estudios de OCDE o de la Comisión Europea.
      </p>
      <p>
        Esta oportunidad dependerá lógicamente de la gestión que se haga por parte de las administraciones públicas y de
        la capacidad de los sectores privados para evitar los riesgos de la transición y aprovechar los nuevos nichos de
        mercado. Aunque actualmente para algunos sectores de difícil descarbonización (acero, cemento, transporte
        pesado, marítimo o aéreo) la mitigación supondrá un coste, en otros sectores como el sector eléctrico
        (renovables) o el transporte de pasajeros (vehículos eléctricos) va suponer o supone ya una reducción de costes
        frente a las alternativas fósiles que debería transmitirse en forma de menores precios a los consumidores
        finales.
      </p>
      <h4>¿Cómo se repartirán los costes o beneficios de la transición energética entre países?</h4>
      <p>
        En términos generales, los países exportadores netos de combustibles fósiles serán los que sufrirán los mayores
        costes, frente a los importadores netos que obtendrán mayores beneficios. No obstante, los impactos dependerán
        del grado de aprovechamiento de las oportunidades y minimización de los riesgos financieros, para lo cual la
        rápida diversificación de las economías en la línea del Acuerdo de París será fundamental.
      </p>
      <p>
        Según IRENA (2019), el cumplimiento del Acuerdo de París, a quien más beneficiará será a la Unión Europea, donde
        el empleo aumentaría en 2,4% y dentro de la UE hace una especial mención a los países del sur de Europa que
        serían los más beneficiados a nivel global por las políticas de mitigación, y para los que proyecta aumento del
        2,9% en el empleo y un 9,4% en el PIB, respecto al escenario tendencial en 2050. También se vería beneficiado
        América del Norte o China. Las regiones que más efectos negativos sufrirían, según IRENA, serían los países
        Oriente Medio y Norte de África, y todos los países de la OPEC. No obstante, es importante mencionar que 4 de
        cada 5 habitantes del planeta se encuentran en países que son importadores netos de combustibles fósiles.
      </p>
      <h4>¿La transición energética puede ser negativa desde el punto de vista de la equidad?</h4>
      <p>
        El impacto de la transición energética sobre la equidad dependerá del diseño de las políticas que se lleven a
        cabo para su consecución, pero no tiene porque ser regresiva y, al contrario, podría ser progresiva y reducir la
        desigualdad existente. La transición modificará y afectará consumos y sectores que están relacionados con grupos
        de rentas bajos. Así, la transición energética ha de acompañarse de medidas que aseguren una transición justa e
        inclusiva. La experiencia de otros países, como la reforma fiscal verde en Dinamarca, o las medidas para reducir
        emisiones en Canadá, demuestran que la transición puede ser inclusiva e intentar no afectar negativamente a la
        equidad. Del mismo modo otras propuestas como el Green New Deal en USA, van dirigidas a favorecer una transición
        energética en la cual tanto los costes como los beneficios se repartan de manera equitativa en la sociedad.
      </p>
      <h4>¿Cómo afectará a las rentas bajas o grupos vulnerables o sobre la pobreza energética?</h4>
      <p>
        Al igual que con la equidad, dependerá en gran medida del tipo de políticas que acompañen la transición
        energética. En primer lugar, el impacto sobre los grupos más vulnerables dependerá de los bienes afectados y de
        los patrones de consumo de los hogares según sus ingresos. En el caso español, si se incrementan los precios de
        los carburantes el impacto será mayor en sobre las rentas medias y altas, así como la población rural, ya que
        las rentas más bajas que viven en zonas urbanas hacen un mayor uso del transporte público. Sin embargo, los
        incrementos en las facturas de la calefacción o la electricidad son regresivos pues las rentas más bajas
        destinan un mayor porcentaje de su renta a estos gastos. Así, dependiendo del bien afectado, los costes de la
        transición serán más o menos regresivos y afectarán a unos grupos sociales o a otros.
      </p>
      <p>
        En segundo lugar, son esenciales las políticas que intenten compensar a los grupos más vulnerables y a los
        consumidores. En el caso de que los costes de la transición afecten más a los más vulnerables, serán necesarias
        políticas de compensación que usen parte de los ingresos del estado para asegurar una transición energética
        justa e inclusiva. En esta línea se han postulado diferentes propuestas como, por ejemplo, el “carbon fee and
        dividend”, defendida en Estados Unidos por 27 premios Nobel de Economía, también en España, y ya aplicada en
        Canadá, donde los ingresos de los nuevos impuestos ambientales van acompañados de una devolución directa en las
        cuentas bancarias para todas las familias, demostrando que políticas bien diseñadas pueden acelerar la
        transición energética sin necesidad de afectar más a los más vulnerables.
      </p>
      <h3>Bloque IV: Ciudadanía, cambio climático y transición energética </h3>
      <h4>¿Cuál es la percepción de la ciudadanía sobre la crisis climática?</h4>
      <p>
        La percepción de la ciudadanía sobre el cambio climático depende de lugares y ha evolucionado con el tiempo,
        aunque en los últimos años ha aumentado notablemente la comprensión del problema, la preocupación al respecto y
        la demanda de soluciones urgentes, como lo demuestran las movilizaciones y manifestaciones a nivel global
        impulsadas por un nuevo movimiento juvenil global. Sin embargo, todavía existen todavía algunas diferencias
        entre los resultados de la ciencia y la interpretación de la ciudadanía, como es lógico.
      </p>
      <p>
        En el caso de la ciudadanía española existen encuestas recientes que dan información relevante. Según un
        reciente cuestionario (11/2018) del Centro de Investigaciones Sociológicas (CIS) la creencia en la existencia
        del cambio climático es del 83%, y otro estudio más reciente (Lázaro et al., 04/2019) lo elevaba al 97%. Un
        58,1% de personas que atribuyen amplia influencia de la acción de los seres humanos (la industria, coches,
        gases, etc.) y otro 35,3% bastante influencia, por sólo 1,5% que le atribuyen poca y 0,2% nada. Es decir, la
        ciudadanía española cree en la existencia de cambio climático y en la responsabilidad humana del problema, y el
        negacionismo climático es muy marginal.
      </p>
      <p>
        La ciudadanía además cree que sí pueden hacer algo para luchar contra el problema. El 72% de los individuos, en
        promedio, consideran que aunque no es una dificultad personal y que parte de la responsabilidad recae en los
        gobiernos y las empresas, sí pueden hacer algo por el CC.
      </p>
      <h4>¿Varía esa percepción por razones de ideología, género, edad u otras características?</h4>
      <p>
        Respecto a la existencia del cambio climático y su origen humano hay diferencias por edad, siendo mayor la
        aceptación de que hay CC cuanto más joven es la persona, y mayor nivel educativo. Por ideología, es mayor la
        aceptación entre aquellos que se autoubican en la izquierda. Sin embargo, no se aprecian diferencias marcadas
        por género.
      </p>
      <p>
        Respecto a las soluciones y acciones, los individuos que tienen entre 35 y 50 años consideran más que la media
        que pueden hacer algo por el CC. Del mismo modo, si atendemos al género, la probabilidad de que las mujeres
        consideren que pueden hacer algo por el CC y no sea una dificultad personal es ligeramente mayor respecto a la
        media. Los grupos que más consideran que pueden hacer algo por el CC son los y las estudiantes, los individuos
        con estudios superiores y por ideología (autoubicación) de izquierdas.
      </p>
      <h4>¿Cuál es el papel de la ciudadanía en la transición energética?</h4>
      <p>
        El papel de la ciudadanía en la transición energética es clave desde el punto de vista de nuestras elecciones
        políticas y de los representantes que marcan las prioridades de la agenda, pero también como agentes
        participativos de la transición energética y como consumidores de bienes y productos.{' '}
      </p>
      <p>
        Desde un el punto de vista de consumidores nuestras elecciones pueden determinar en gran medida, por ejemplo, la
        oferta de las grandes compañías energéticas. Si demandamos garantías de origen renovable a nuestras compañías
        eléctricas, esto obligará de forma a dar el paso en el proceso de descarbonización. Hoy en día ya existen
        alternativas reales para realizar este cambio mediante distribuidores convencionales o cooperativas energéticas
        de energía renovable (ej. GOIENER) que están tomando cada vez más fuerza. En otros países europeos, como
        Alemania, esta opción ha experimentado un crecimiento sustancial. De hecho, según un estudio publicado por el
        Wuppertal Institute for Climate, Environment and Energy”, el 65% de las cooperativas energéticas de energías
        renovables han experimentado un crecimiento en el número de socios en los últimos 10 años.
      </p>
      <h4>¿Qué es la huella de carbono?</h4>
      <p>
        Diariamente los seres humanos realizamos múltiples actividades que dejan una huella sobre el planeta. En este
        sentido, la huella de carbono es un indicador que contabiliza la cantidad total de gases de efecto invernadero
        (GEIs) emitidos a la atmósfera, directa o indirectamente, a consecuencia de las actividades de un individuo, un
        grupo social, un país, etc. El IPCC ha destacado que los estilos de vida y los hábitos de consumo de las
        personas tienen gran influencia sobre las emisiones de GEIs a escala global (IPCC 2014).
      </p>
      <h4>¿Qué acciones pueden ayudar a reducir nuestra huella como consumidores?</h4>
      <p>
        Según un reciente estudio de IGES 2019, para cumplir el objetivo de 1,5°C marcado en París, las huellas de
        carbono actuales vinculadas a los estilos de vida de las personas deben reducirse muy considerablemente (por
        ejemplo, en países desarrollados como Finlandia y Japón entre un 80-93% para 2050; y, en países en desarrollo
        como China, Brasil e India entre un 23-84% para 2050). Para ello, existen múltiples acciones que pueden ayudar a
        reducir nuestra huella como consumidores. Por ejemplo, realizar viajes y desplazamientos en transporte público o
        bicicleta en lugar de utilizar automóviles, usar automóviles eléctricos e híbridos, realizar viajes compartidos,
        vivir más cerca de los lugares de trabajo y en espacios más pequeños, usar electricidad de origen renovable para
        autoconsumo, usar bombas de calor para el control de la temperatura, reducir el consumo excesivo de carne roja
        en la dieta, reducir el uso de plástico, consumir productos locales, reducir consumos superfluos, reutilizar y
        reciclar, etc.
      </p>
      <h4>¿Es posible hacer compatible el actual modelo de consumo y la preservación de los límites del planeta?</h4>
      <p>
        El consumismo tiene graves consecuencias sobre el planeta. El IPCC ha anunciado en su último informe que
        necesitamos un cambio del modelo de producción y consumo para combatir la crisis climática. Ya no es suficiente
        con transformar únicamente el sector energético, si no que otros sectores, incluyendo nuestros hábitos de
        consumo, deben ser adaptados. Algunas de las modificaciones necesarias implicarían cambios en la movilidad, con
        el uso de sistemas de transporte colectivo e incluso reducción en el número de desplazamientos, mayores
        esfuerzos en reciclaje y reutilización de nuestros bienes, ajustes en los usos energéticos y en nuestra dieta,
        entre otras muchas soluciones al alcance de los ciudadanos. No parece viable mantenernos dentro de los límites
        planetarios si todos los ciudadanos del planeta tuvieran los hábitos de consumo de los países de mayor renta.
      </p>
      <h3>Bloque V: Co-beneficios y riesgos de la transición energética</h3>
      <h4>¿Qué ventajas o desventajas adicionales tiene la descarbonización de la economía?</h4>
      <p>
        Aunque la razón principal para descarbonizar la economía es reducir las emisiones de efecto invernadero, las
        transformaciones necesarias para decarbonizarla tienen varias ventajas y desventajas, que dependen de cómo
        transformamos la economía.
      </p>
      <p>
        Por ejemplo, las tecnologías bajas en carbono también suelen ser baja en otros contaminantes que afectan a
        nuestra salud. Sin embargo, el uso de biomasa, aunque sea renovable en términos de gases de efecto invernadero,
        provoca más contaminación del aire que los hidrocarburos. En términos de uso de agua, las renovables como la
        eólica y solar usan mucha menos agua en su ciclo de vida que las energías fósiles, pero la huella hídrica de
        otras energías bajas en carbón, como la biomasa y nuclear, es mayor que los de energías fósiles. Por otro lado,
        la producción de energías renovables como la eólica, solar y biomasa requieren de mucho más espacio que energías
        centralizadas como las energías fósiles y la nuclear. La necesidad de más espacio puede tener desventajas en
        términos estéticos y de biodiversidad, pero también ventajas para la economía rural.
      </p>
      <p>
        Fijándonos en el suministro de energía, las energías renovables tienen la ventaja de que suelen ser producidas
        dentro del país, mientras que el suministro de energías fósiles depende de las importaciones provenientes de
        otros países, que no siempre son los más estables políticamente. Por otro lado, el uso de energías
        intermitentes, como la eólica y solar, produce más riesgos para el suministro de electricidad. Sin embargo, el
        uso de energías producidas dentro del país genera más empleo que el uso de energías importadas.
      </p>
      <h4>¿Qué impacto tienen las energías renovables para el uso de tierra?</h4>
      <p>
        La producción de energías renovables como la eólica, solar y biomasa requieren de mucho más espacio que energías
        centralizadas como las energías fósiles y nuclear. Por eso, una transición a energías renovables tendrá impactos
        para el uso de la tierra.
      </p>
      <p>
        En concreto, los parques eólicos suelen estar instalados en zonas más altas o cerca del mar, donde suele haber
        más viento. El uso de estos suelos no suele competir con otros usos de tierra productivos, pero el impacto
        estético de estos parques suele ser relativamente grande. Por otro lado, los parques solares suelen ser
        instalados en zonas planas, que también pueden ser utilizados para otros usos productivos como la agricultura.
        Sin embargo, el suelo utilizado para parques solares no necesita ser fértil, y se podrían usar principalmente
        las zonas más secas y abandonadas para energía solar, limitando su impacto a la competición por el suelo.
      </p>
      <p>
        Los parques solares también tienen un impacto estético, aunque es menor que el de los parques eólicos, ya que
        las construcciones de energía solar son de bastante menor altura.
      </p>
      <p>
        La producción de energía de biomasa y biocombustibles no procedentes de residuos requiere de mucho más suelo por
        unidad de energía que la energía solar, y el suelo utilizado para producir biomasa tiene que ser suficientemente
        fértil. Por eso, el uso de estas energías incrementa significativamente la competición por el uso del suelo. El
        impacto estético de la producción de biomasa depende de qué tipo de suelo se esté usando: sustituyendo suelo
        previamente usado para cultivos, el impacto es insignificante, pero usando suelo no dedicado a la agricultura,
        como bosques, el impacto es muy relevante. Además, el uso descontrolado de biomasa y biocombustibles en Europa
        suele tener impactos indirectos importantes fuera de Europa, incrementando la deforestación en países como
        Indonesia, Brasil, y EE.UU.
      </p>
      <h4>¿Cuál es la relación entre el Acuerdo de París y los ODS?</h4>
      <p>
        El cambio climático y el desarrollo sostenible tienen múltiples vínculos reflejados en dos acuerdos
        transformadores adoptados en 2015: la Agenda 2030 para el Desarrollo Sostenible y el Acuerdo de París sobre el
        Cambio Climático. El Acuerdo de París busca reducir la emisión de gases de efecto invernadero de la atmósfera
        como medida para combatir el cambio climático, cuyos efectos ponen en peligro la consecución de las dimensiones
        incluidas en la Agenda 2030 y los ODS. De la misma manera, estas sinergias también permiten avanzar de manera
        conjunta hacia ambas metas.
      </p>
      <p>
        En el plano social, limitar el deterioro de los sistemas alimentarios para combatir el hambre y reducir el
        riesgo de eventos meteorológicos extremos para proteger a las comunidades. En el económico, la creación de
        empleos en el sector de las energías renovables y del reciclaje necesarios para la transición energética.
        Además, una gestión inclusiva y sostenible de los recursos naturales durante esta transición tiene el potencial
        de construir sociedades más justas y libres de conflicto asegurando el acceso de la población a las necesidades
        básicas en sitios donde la escasez de recursos podría generar tensiones. Por último, ambos acuerdos resaltan la
        necesidad de las alianzas internacionales como vehículo para abordar desafíos globales a través la movilización
        de recursos financieros, desarrollo de capacidades y cooperación tecnológica.
      </p>
      <p>
        Reconocer los vínculos entre el Acuerdo de París y la Agenda 2030 es necesario para hacer las preguntas
        adecuadas e identificar las sinergias para encontrar soluciones sostenibles.
      </p>
      <h4>¿De qué manera está afectando el cambio climático al aumento de las migraciones?</h4>
      <p>
        El aumento de la temperatura global está derritiendo las capas de hielo y provocando que las aguas del océano se
        expandan lo cual está derivando en una subida del nivel del mar. Se estima que para finales de siglo unas 48
        islas acaben sumergidas bajo el agua (Desmuk, 2019). Pero el nivel del mar no es el único factor que está
        afectando a los movimientos migratorios; los problemas derivados de la contaminación atmosférica, la
        acidificación del océano, la pérdida de biodiversidad o el cambio en los patrones en las precipitaciones están
        agravando una crisis ya existente con personas refugiadas huyendo de conflictos humanitarios. 2017 fue el año
        con mayor número personas obligadas a huir de sus países (68.5 millones) y un tercio de todos estos
        desplazamientos fueron motivados por anomalías en el clima, especialmente relacionadas con inundaciones,
        tormentas e incendios causados por episodios de sequía (Rigaud et al.). Estimaciones del Banco Mundial cifran en
        143 millones el número de refugiados climáticos para el 2050 procedentes exclusivamente de América Latina,
        África subsahariana y el Sudeste Asiático (Podesta, 2019).
      </p>
      <p>
        Para anticiparse a las consecuencias derivadas de esta crisis, es necesario actuar con urgencia y establecer un
        marco legal que reconozca el cambio climático como causante de las migraciones. El nexo establecido entre el
        cambio climático y la migración requiere una estrategia legal extensa para obligar a los países a cooperar en la
        protección de los migrantes climáticos. La Convención de Ginebra sobre el Estatuto de los Refugiados de 1951
        reforzó los derechos en materia de solicitud de asilo para las personas refugiadas, pero su protección no se
        extiende a los migrantes climáticos. Uno de los primeros pasos en este sentido se dio en 2018 cuando la Asamblea
        General de la ONU adoptó el Pacto Mundial sobre Refugiados reconociendo que el clima, la degradación ambiental y
        los desastres naturales interactúan cada vez más con los impulsores de los movimientos de refugiados (UNHCR,
        2019). Abordar este problema multidimensional requiere no solo la voluntad política y la cooperación
        internacional, sino también la contribución de la innovación y la tecnología a través de, por ejemplo, la
        vigilancia por satélite.
      </p>
      <h4>¿Cuál es la relación entre el Acuerdo de París y la reducción de la contaminación atmosférica?</h4>
      <p>
        Según la OMS (2016), la contaminación atmosférica es la causa de 7.2 muertes prematuras cada año (World Health
        Organization, 2016), relacionadas principalmente con problemas en los sistemas respiratorios y cardiovasculares.
        El Acuerdo de París establece unos objetivos climáticos que necesitan rápida reducción de las emisiones de gases
        de efecto invernadero (GEIs). Siendo, los combustibles fósiles uno de los principales factores para el aumento
        de la contaminación atmosférica es claro que la lucha contra la contaminación y contra el cambio climático tiene
        importantes sinergias. En este contexto, se puede concluir que la lucha contra el cambio climático va a generar
        importantes co-beneficios en términos de salud pública.
      </p>
      <p>
        Además, existen estudios que demuestran que los costes de las políticas para luchar contra el cambio climático a
        nivel global serían compensados por los co-beneficios económicos en términos de salud pública (Markandya,
        Sampedro et al., 2018, The Lancet). Por último, hay que tener en cuenta que la contaminación atmosférica también
        tiene otros efectos negativos en otros sistemas como, por ejemplo, en los sistemas agrícolas mediante la
        reducción de la productividad en las cosechas y, por lo tanto, afectando a la seguridad alimentaria.
      </p>
    </BaseLayout>
  )
}

const BaseLayout = styled(Layout)`
  height: 100% !important;
  padding: 100px 40px;
`
