import React from 'react'
import { Col, Layout, Row, Tabs } from 'antd'
import styled from 'styled-components'
import YouTube from 'react-youtube'
import { MainMenu } from '../layouts/components/MainMenu'
import { SmallVisor } from '../layouts/components/SmallVisor'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { ResourceCard } from '../layouts/components/ResourceCard'
import { ReportCard } from '../layouts/components/ReportCard'
import { useGetResources } from '../services/resources.service'
import { useGetMultimedia } from '../services/multimedia.service'
import { Helmet } from "react-helmet";

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

export const Resources: React.FC = () => {
  const resourcesRequest = useGetResources()
  const resources = resourcesRequest.data || []

  const multimediaRequest = useGetMultimedia()
  const multimedia = multimediaRequest.data || []
  
  return (
    <CustomLayout>
      <Helmet>
        <title>Estudios, publicaciones y recursos</title>
        <meta name="description" content="Estudios, publicaciones y recursos sobre el cambio climático, la transición energética y su evolución" />
        <meta name="keywords" content="Cambio climático, transición ecológica, artículos, noticias, estudios, publicaciones, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MainMenu />
      <SmallVisor pageTitle="Recursos" />
      <GeneralContainer>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Informe anual" key="1">
            <Row gutter={[16, 40]}>
              {resources
                .filter(r => r.category === 'annual-report' && r.attachment.length > 0)
                .map(r => (
                  <Col xs={24} md={6} key={`r-${r.id}`}>
                    <ReportCard
                      reportName={r.name}
                      reportUrl={r.attachment[0].url}
                      imageUrl={r.screenshot}
                    />
                  </Col>
                ))}
            </Row>
          </TabPane>
          <TabPane tab="Estudios" key="2">
            <Row gutter={[16, 40]}>
              {resources
                .filter(r => r.category === 'studies' && r.attachment.length > 0)
                .map(r => (
                  <Col xs={24} md={6} key={`r-${r.id}`}>
                    <ResourceCard
                      resourceName={r.name}
                      resourceUrl={r.attachment[0].url}
                      imageUrl={r.screenshot}
                    />
                  </Col>
                ))}
            </Row>
          </TabPane>
          <TabPane tab="Multimedia" key="3">
            {multimedia
              .map(m => (
              <Row gutter={[16, 40]} key={`m-${m.id}`}>
                <Col xs={24} lg={7} xl={10}>
                  <h3>{m.title}</h3>
                  <p>{m.description}</p>
                </Col>
                <Col xs={24} lg={17} xl={14}>
                  <StyledYouTube videoId={m.video_id} />
                </Col>
              </Row>
            ))}
          </TabPane>
          <TabPane tab="Otras publicaciones" key="4">
            <Row gutter={[16, 40]}>
              {resources
                .filter(r => r.category === 'other' && r.attachment.length > 0)
                .map(r => (
                  <Col xs={24} md={6} key={`r-${r.id}`}>
                    <ResourceCard
                      resourceName={r.name}
                      resourceUrl={r.attachment[0].url}
                      imageUrl={r.screenshot}
                    />
                  </Col>
                ))}
            </Row>
          </TabPane>
        </Tabs>
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}

const CustomLayout = styled(Layout)`
  background: #fff;
`

const StyledYouTube = styled(YouTube)`
  margin: 10px auto 50px;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    float: right;
  }
`
