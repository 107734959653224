import React from 'react'
import styled from 'styled-components'
import { useHomeDownload } from '../../services/home-download.service'
import { getAbsoluteServerUrl } from '../../config/config'

export const DownloadModule: React.FC = () => {
  const homeDownloadRequest = useHomeDownload()
  const homeDownload = homeDownloadRequest.data || undefined

  return homeDownload && homeDownload.attachment ? (
    <DwnModule>
      <a href="/">
        <img src="iconos/icono-descarga.png" alt="Descarga" width="60px" height="57px" />
      </a>
      <div>
        <h3>Descarga ahora</h3>
        <a href={homeDownload.attachment[0].url} target="_blank" rel="noopener noreferrer">
          <b>{homeDownload.name}</b>
        </a>
      </div>
    </DwnModule>
  ) : (
    <DwnModule></DwnModule>
  )
}

const DwnModule = styled.div`
  padding-left: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 768px) {
    border-left: 3px solid #2fcdde;
  }

  p {
    margin-bottom: 0;
  }

  img {
    margin: 20px;
  }
`
