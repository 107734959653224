import React from 'react'
import { Col, Row, Layout } from 'antd'
import styled from 'styled-components'
import { MainMenu } from '../layouts/components/MainMenu'
import { SmallVisor } from '../layouts/components/SmallVisor'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { PublicationCard } from '../layouts/components/PublicationCard'
import { Helmet } from "react-helmet";

export const ClimateAction: React.FC = () => {
  return (
    <CustomLayout>
      <Helmet>
        <title>El cambio climático: La visión del Observatorio.</title>
        <meta name="description" content="Visión del Observatorio para la Transición Energética y al Acción climática sobre el Cambio Climático" />
        <meta name="keywords" content="Cambio climático, transición ecológica, artículos, noticias, estudios, publicaciones, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MainMenu />
      <SmallVisor pageTitle="El cambio climático" />
      <GeneralContainer>
        {
          <Row gutter={[16, 40]}>
            <Col>
              <p>
                El <b>cambio climático</b> es una de las mayores amenazas a las que se enfrenta la humanidad.
              </p>
              <p>
                La actividad humana y la quema de combustibles fósiles han aumentado de forma drástica la concentración
                de gases de efecto invernadero en la atmósfera, lo que está provocando un calentamiento global y la
                alteración del clima en todo el planeta. Los impactos del cambio climático están teniendo una gran
                incidencia a nivel global y ésta se intensificará en los próximos años si no actuamos a tiempo y con
                determinación.
              </p>
              <p>
                En el caso de España, y desde el comienzo de la serie en 1961, la temperatura media ha aumentado un 1,0
                ºC, siendo 2017 y 2020 los años más calurosos registrados. También se ha observado un incremento en la
                frecuencia e intensidad de eventos meteorológicos extremos como las olas de calor.
              </p>
              <div className="image-w-note">
                <img src="/text-images/accion-climatica/stripes.png" alt="diagrama emisiones por franjas 1850-2019" />
                <small>
                  Fuente:{' '}
                  <a href="https://showyourstripes.info/" target="_blank" rel="noopener noreferrer">
                    https://showyourstripes.info/
                  </a>
                </small>
              </div>
              <p>
                La crisis climática es un problema global que necesita de una acción coordinada y del esfuerzo de todos
                los países y agentes. El Acuerdo de París es el elemento central de la <b>acción por el clima</b> a
                nivel internacional y establece el objetivo de limitar el aumento de la temperatura media mundial por
                debajo de los 2°C, y hacer esfuerzos para no rebasar los 1,5°C respecto los niveles preindustriales.
                Para ello, las emisiones globales de gases de efecto invernadero (GEI) tienen que reducirse urgentemente
                y así alcanzar la neutralidad climática a mediados de siglo.
              </p>
              <p>
                Para lograr estos objetivos de reducción de emisiones es necesario realizar una{' '}
                <b>transición energética</b> que transforme el actual sistema energético basado en las energías fósiles
                (principal fuente de emisión de GEI) en un sistema energético más eficiente y basado en las energías
                renovables.
              </p>
              <p>
                En el caso de España, la Estrategia de Descarbonización a Largo Plazo (
                <a
                  href="https://www.miteco.gob.es/es/prensa/ultimas-noticias/el-gobierno-aprueba-la-estrategia-de-descarbonizaci%C3%B3n-a-largo-plazo-que-marca-la-senda-para-alcanzar-la-neutralidad-clim%C3%A1tica-a-2050/tcm:30-516141"
                  target="_blank"
                  rel="noopener noreferrer">
                  ELP
                </a>
                ) establece el objetivo de alcanzar la neutralidad climática en 2050, lo que supone reducir un 90% las
                emisiones para ese año con respecto a 1990. Para alcanzar dicho objetivo, el Plan Nacional Integrado de
                Energía y Clima (
                <a href="https://www.miteco.gob.es/es/prensa/pniec.aspx" target="_blank" rel="noopener noreferrer">
                  PNIEC
                </a>
                ) establece los siguientes objetivos intermedios a 2030:{' '}
              </p>
              <ul>
                <li>23% de reducción de emisiones de gases de efecto invernadero (GEI) respecto a 1990</li>
                <li>42% de renovables sobre el uso final de la energía </li>
                <li>39,5% de mejora de la eficiencia energética</li>
                <li>74% de energía renovable en la generación eléctrica (y 100% en 2050).</li>
              </ul>
              <p>
                La transición energética supone un reto tecnológico, pero también un reto económico, social, y ambiental
                que requiere de la colaboración de todos los sectores y de la sociedad. Asimismo, esta transición
                representa una oportunidad para generar empleos y aumentar el bienestar colectivo.{' '}
              </p>
              <p>Para más información puedes descargarte nuestras publicaciones:</p>
            </Col>
          </Row>
        }
      </GeneralContainer>
      <GeneralContainer>
        {
          <Row gutter={[16, 40]}>
            <Col xs={24} md={8}>
              <PublicationCard
                resourceName="Cambio Climático"
                resourceUrl="/reports/cambio-climatico-OTEA.pdf"
                imageUrl="/text-images/accion-climatica/docs-images/cambio-climatico.jpg"
              />
            </Col>
            <Col xs={24} md={8}>
              <PublicationCard
                resourceName="Acción por el Clima"
                resourceUrl="/reports/accion-por-el-clima-OTEA.pdf"
                imageUrl="/text-images/accion-climatica/docs-images/accion-clima.jpg"
              />
            </Col>
            <Col xs={24} md={8}>
              <PublicationCard
                resourceName="Transición Energética"
                resourceUrl="/reports/transicion-energetica-OTEA.pdf"
                imageUrl="/text-images/accion-climatica/docs-images/transicion-energetica.jpg"
              />
            </Col>
          </Row>
        }
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}

const CustomLayout = styled(Layout)`
  background: #fff;

  .image-w-note {
    float: right;
    margin-left: 50px;
    text-align: right;

    img {
      width: 450px;
      display: block;
    }
  }
`
