import React from 'react'
import { Col, Row, Layout, Tabs, Image } from 'antd'
import styled from 'styled-components'
import { MainMenu } from '../layouts/components/MainMenu'
import { TeamMemberCard } from '../layouts/components/TeamMemberCard'
import { ColaboratorLogo } from '../layouts/components/ColaboratorLogo'
import { SmallVisor } from '../layouts/components/SmallVisor'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { useGetTeammembers } from '../services/teammembers.service'
import { useGetColaborators } from '../services/colaborators.service'
import { Helmet } from "react-helmet";

const { TabPane } = Tabs

export const Observatory: React.FC = () => {

  const teammembersRequest = useGetTeammembers()
  const teammembers = teammembersRequest.data || []

  const colabRequest = useGetColaborators()
  const colaborators = colabRequest.data || []

  return (
    <CustomLayout>
      <Helmet>
        <title>El Observatorio para la transición energética y la acción climática</title>
        <meta name="description" content="El Observatorio para la transición energética y la acción climática: objetivos, equipo y entidades colaboradoras" />
        <meta name="keywords" content="Cambio climático, transición ecológica, artículos, noticias, estudios, publicaciones, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MainMenu />
      <SmallVisor pageTitle="Sobre nosotros" />
      <GeneralContainer>
        {
          <Row gutter={[16, 40]}>
            <Col>
              <p>
                <b>
                  El Observatorio de la Transición Energética y la Acción Climática (OTEA) tiene como objetivo aportar
                  datos y análisis de calidad para el seguimiento de la descarbonización en España y de sus
                  implicaciones económicas, sociales y ambientales. La misión y la filosofía de OTEA es aportar una
                  información fiable, actualizada y de fácil acceso para toda la sociedad.
                </b>
              </p>
              <ul>
                <li>
                  <b>Información abierta y actualizada:</b> nuestro objetivo es que toda la información esté disponible
                  de forma fácil y gratuita. El observatorio es una plataforma de acceso abierto bajo una licencia
                  Creative Commons. Incorporamos indicadores adelantados que nos permiten hacer un mejor seguimiento de
                  la descarbonización en España e información actualizada en todo momento.
                </li>
                <li>
                  <b>Información integral:</b> hacemos un seguimiento sistémico de la descarbonización, incluyendo tanto
                  la dimensión climática y energética, como los aspectos económicos, sociales y ambientales. Queremos
                  ser un lugar de referencia donde encontrar información integral y relevante sobre la transición
                  energética y la acción climática.
                </li>
                <li>
                  <b>Información rigurosa e innovadora:</b> nuestro compromiso es trabajar para asegurar la calidad de
                  la información y de los análisis puestos a disposición por el observatorio. Además de utilizar e
                  integrar información de otras fuentes, también desarrollamos métodos propios que nos permiten crear
                  nuevo conocimiento sobre la transición energética y la acción climática.
                </li>
              </ul>
            </Col>
          </Row>
        }
      </GeneralContainer>
      <GeneralContainer>
        {
          <Row gutter={[16, 40]}>
            <Col>
              <h2>El equipo</h2>
              <p>
                El Observatorio de la Transición Energética y la Acción Climática (OTEA) está formado por un grupo
                multidisciplinar de investigadoras/es del Basque Centre for Climate Change (BC3) expertas/os en materia
                de transición energética y acción climática.
              </p>
            </Col>
          </Row>
        }
      </GeneralContainer>
      <GeneralContainer>
        {
          <Tabs defaultActiveKey="1">
            <TabPane tab="Equipo" key="1">
              <Row gutter={[8, 16]}>
                {teammembers
                  .filter(tm => tm.role !== 'comite')
                  .map(tm => (
                    <Col xs={12} sm={6} xl={4} key={`tm-${tm.id}`}>
                      <TeamMemberCard
                        firstName={tm.name}
                        lastName={tm.surname}
                        pageUrl={tm.link}
                        imageUrl={tm.attachment.length ? tm.attachment[0].url : ''}
                      />
                    </Col>
                  ))}
              </Row>
            </TabPane>
            <TabPane tab="Dirección" key="2">
              <Row gutter={[8, 16]}>
                {teammembers
                  .filter(tm => tm.role === 'direccion')
                  .map(tm => (
                    <Col xs={12} sm={6} xl={4} key={`tm-${tm.id}`}>
                      <TeamMemberCard
                        firstName={tm.name}
                        lastName={tm.surname}
                        pageUrl={tm.link}
                        imageUrl={tm.attachment.length ? tm.attachment[0].url : ''}
                      />
                    </Col>
                  ))}
              </Row>
            </TabPane>
            <TabPane tab="Coordinación" key="3">
              <Row gutter={[8, 16]}>
                {teammembers
                  .filter(tm => tm.role === 'coordinacion')
                  .map(tm => (
                    <Col xs={12} sm={6} xl={4} key={`tm-${tm.id}`}>
                      <TeamMemberCard
                        firstName={tm.name}
                        lastName={tm.surname}
                        pageUrl={tm.link}
                        imageUrl={tm.attachment.length ? tm.attachment[0].url : ''}
                      />
                    </Col>
                  ))}
              </Row>
            </TabPane>
          </Tabs>
        }
      </GeneralContainer>
      <GeneralContainer>
        <h2>Comité asesor</h2>
        <Row gutter={[8, 16]}>
          {teammembers
            .filter(tm => tm.role === 'comite')
            .map(tm => (
              <Col xs={12} sm={6} xl={4} key={`tm-${tm.id}`}>
                <TeamMemberCard
                  firstName={tm.name}
                  lastName={tm.surname}
                  pageUrl={tm.link}
                  imageUrl={tm.attachment.length ? tm.attachment[0].url : ''}
                />
                <MemberTitle><b>{tm.name} {tm.surname}</b><br/>{tm.organization}</MemberTitle>
              </Col>
            ))}
        </Row>
      </GeneralContainer>
      <GeneralContainer>
        <h2>Colaboradores</h2>
        <p>
        OTEA es una iniciativa del BC3 (Basque Centre for Climate Change), cofinanciada por Iberdrola y el programa de ayudas a 
        centros de excelencia María de Maeztu del Ministerio de Ciencia e Innovación. Asimismo, OTEA colabora con diferentes 
        organizaciones para difundir información y crear nuevo conocimiento como AEMENER (Asociación Española de Mujeres de la Energía), 
        la AEEE (Asociación Española para la Economía Energética), SEO/BirdLife y otras organizaciones que esperamos vayan adhiriéndose en un futuro.
        </p>
        <Row justify="space-between" align="middle">
          {colaborators
            .map(colab => (
              <ColaboratorLogo
                key={colab.id}
                name={colab.name}
                logo={colab.attachment.length ? colab.attachment[0].url : ''}
                url={colab.url} />
            ))}
        </Row>
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}
const CustomLayout = styled(Layout)`
  background: #fff;
`

const MemberTitle = styled.p`
  text-align: center;
  margin-top: 20px;
`
