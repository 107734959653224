import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.scss'
import numeral from 'numeral'
import { Visualization } from './pages/Visualization'
import { Home } from './pages/Home'
import { ClimateChange } from './pages/ClimateChange'
import { ClimateAction } from './pages/ClimateAction'
import { EnergeticTransition } from './pages/EnergeticTransition'
import { Observatory } from './pages/Observatory'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { CookiesPolicy } from './pages/CookiesPolicy'
import { Blog } from './pages/Blog'
import { PostPage } from './pages/PostPage'
import { Indicators } from './pages/Indicators'
import { Figures } from './pages/Figures'
import { Resources } from './pages/Resources'
import { Transition } from './pages/Transition'
import { Test } from './pages/Test'

// load a locale
numeral.register('locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function () {
    return 'º'
  },
  currency: {
    symbol: '€',
  },
})

numeral.locale('es')

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/test" component={Test} />
        <Route path="/visualization/:chart/:subChart" component={Visualization} />
        <Route path="/indicators/:chart/:subChart" component={Indicators} />
        <Route path="/figures/:figure/:size" component={Figures} />
        <Route path="/figures/:figure" component={Figures} />
        <Route path="/cambioclimatico" component={ClimateChange} />
        <Route path="/accionclima" component={ClimateAction} />
        <Route path="/transicionenergetica" component={EnergeticTransition} />
        <Route path="/observatorio" component={Observatory} />
        <Route path="/transicion" component={Transition} />
        <Route path="/recursos" component={Resources} />
        <Route path="/noticias/:url" component={PostPage} />
        <Route path="/noticias" component={Blog} />
        <Route path="/politica-privacidad" component={PrivacyPolicy} />
        <Route path="/cookies" component={CookiesPolicy} />
        <Route path="/bdd/:categorySlug/:subCategorySlug/:indicatorSlug" component={Indicators} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  )
}

export default App
